/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Divider } from '@material-ui/core';
import Header from 'components/Header/Header';
import CustomNavbar from 'components/Navbar/CustomNavbar';
import Routes from 'components/Routes/Routes';
import { RouteTypeEnum } from 'enums/routeType';
import { HomeContainer } from './Home.styled';

const Home = (): JSX.Element => {
  return (
    <HomeContainer>
      <Header />
      <CustomNavbar />
      <Divider />
      <Routes type={RouteTypeEnum.TRUSTED} />
    </HomeContainer>
  );
};
export default Home;
