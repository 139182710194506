export const AuthActionTypes = {
  Login: 'AUTH_LOGIN',
  LoginSuccess: 'AUTH_LOGIN_SUCCESS',
  LoginError: 'AUTH_LOGIN_ERROR',
  RefreshToken: 'AUTH_REFRESH_TOKEN',
  RefreshTokenSuccess: 'AUTH_REFRESH_TOKEN_SUCCESS',
  RefreshTokenError: 'AUTH_REFRESH_TOKEN_ERROR',
  VerifyTokenSuccess: 'AUTH_VERIFY_TOKEN_SUCCESS',
  LogoutUser: 'AUTH_LOGOUT_USER',
  LogoutUserSuccess: 'AUTH_LOGOUT_USER_SUCCESS',
  OAuthRefreshSuccess: 'AUTH_OAUTH_REFRESH_SUCCESS',
  OAuthRefreshError: 'AUTH_OAUTH_REFRESH_ERROR',
  OAuthConnect: 'AUTH_OAUTH_CONNECT',
  OAuthConnectSuccess: 'AUTH_OAUTH_CONNECT_SUCCESS',
  OAuthConnectError: 'AUTH_OAUTH_CONNECT_ERROR',
};
