import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';

export const AddContentFormDiv = styled.div`
  flex-grow: 1;
  max-height: calc(100% - 150px);
`;

export const AddContentFormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-height: calc(100% - 50px);

  > :first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    border-right: solid rgba(100, 100, 100, 0.2) 1px;
    max-width: 299px !important;
  }

  > :last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0;
    transition: flex 0.2s ease;
    margin-left: -6px;
    padding-left: 6px;
  }
`;

export const AddContentFormColumn = styled.div`
  overflow: auto;
  flex: 3;
  padding: 12px 24px;
  width: 50%;
  max-height: 100%;
`;

export const AddContentPreviewContainer = styled(Paper)`
  position: relative;
  border-radius: 10px !important;
  overflow: hidden;
`;

export const AddContentPreviewBar = styled.div`
  position: relative;
  padding: 4px;

  > button {
    display: block;
    margin: 0 0 0 auto;
    z-index: 10;
  }
`;

export const AddContentPreviewBox = styled.div`
  position: relative;
  min-height: 180px;

  > img {
    display: block;
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease;
  }
`;

export const AddContentPreviewSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
`;

export const AddContentFormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  border-top: solid rgba(100, 100, 100, 0.2) 1px;
  justify-content: center;
  height: 50px;
  align-items: center;
  background-color: #fff;

  > button {
    font-size: 13px;
    font-weight: 500;
    padding: 7px 12px;
  }
`;

export const AddContentFormModalContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const AddContentFormModalSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const AddContentFormContainer = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
