/* eslint-disable @typescript-eslint/no-empty-interface */
import { Dispatch } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import { OrderEnum } from 'enums/orderEnum';
import React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { checkAllEntries, checkEntry, getContent, setPagination } from 'store/content/content.actions';
import {
  ICheckAllEntries,
  ICheckEntry,
  IContent,
  IGetContent,
  IHubspotSearchContent,
} from 'store/content/content.interface';
import {
  addSourceLanguage,
  addTargetLanguage,
  getPageLanguages,
  removeSourceLanguage,
  removeTargetLanguage,
} from 'store/languages/languages.actions';
import { IGetPageLanguages, IUpdateLanguages, LanguageTypeEnum } from 'store/languages/languages.interface';
import { getPageLanguagesSelector } from 'store/languages/languages.selectors';
import { togglePreview } from 'store/preview/preview.actions';
import { IPreviewState } from 'store/preview/preview.interface';
import { previewSelector } from 'store/preview/preview.selectors';
import { setPreviewSpinner } from 'store/spinner/spinner.actions';
import { Page } from 'utils/restApplicationClient';

export interface WithEntriesTable {
  handleChangeOrder: (property: string) => () => void;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (size: number) => void;
  handlePreviewEntry: ({ screenshotPreviewUrl: previewUrl }: IHubspotSearchContent) => () => void;
  handleCheckEntry: (
    index: number,
    entry: IHubspotSearchContent,
    id: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checkAllEntries: (value: boolean) => () => void;
  checkForCheckedEntries: (page?: Page<IHubspotSearchContent>) => { indeterminate: boolean; checked: boolean };
  preview: IPreviewState;
}

interface IDispatchProps {
  getContent: (payload: IGetContent) => AppDispatch;
  setPagination: (payload: IGetContent) => AppDispatch;
  checkEntry: (payload: ICheckEntry) => AppDispatch;
  checkAllEntries: (payload: ICheckAllEntries) => AppDispatch;
  togglePreview: (payload: IPreviewState) => AppDispatch;
  setPreviewSpinner: (payload: boolean) => AppDispatch;
  addSourceLanguage: (payload: IUpdateLanguages) => AppDispatch;
  removeSourceLanguage: (payload: IUpdateLanguages) => AppDispatch;
  addTargetLanguage: (payload: IUpdateLanguages) => AppDispatch;
  removeTargetLanguage: (payload: IUpdateLanguages) => AppDispatch;
  getPageLanguages: (payload: IGetPageLanguages) => AppDispatch;
}

interface IStateProps {
  preview: IPreviewState;
  pageLanguages: { [key: string]: Array<string> };
}

interface IProps {
  contentData: IContent;
  contentType: ContentTypeEnum;
  isLoading: boolean;
  disabledCheckboxes?: Array<number>;
}

const mapStateToProps = (state: AppState): IStateProps => ({
  preview: previewSelector(state),
  pageLanguages: getPageLanguagesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getContent: (payload: IGetContent): AppDispatch => dispatch(getContent(payload)),
  setPagination: (payload: IGetContent): AppDispatch => dispatch(setPagination(payload)),
  togglePreview: (payload: IPreviewState): AppDispatch => dispatch(togglePreview(payload)),
  setPreviewSpinner: (payload: boolean): AppDispatch => dispatch(setPreviewSpinner(payload)),
  checkEntry: (payload: ICheckEntry): AppDispatch => dispatch(checkEntry(payload)),
  checkAllEntries: (payload: ICheckAllEntries): AppDispatch => dispatch(checkAllEntries(payload)),
  addSourceLanguage: (payload: IUpdateLanguages): AppDispatch => dispatch(addSourceLanguage(payload)),
  removeSourceLanguage: (payload: IUpdateLanguages): AppDispatch => dispatch(removeSourceLanguage(payload)),
  addTargetLanguage: (payload: IUpdateLanguages): AppDispatch => dispatch(addTargetLanguage(payload)),
  removeTargetLanguage: (payload: IUpdateLanguages): AppDispatch => dispatch(removeTargetLanguage(payload)),
  getPageLanguages: (payload: IGetPageLanguages): AppDispatch => dispatch(getPageLanguages(payload)),
});

type PropType = IDispatchProps & IStateProps & IProps;

export const withEntriesTable = <P extends WithEntriesTable>(
  WrappedComponent: React.ComponentType<P>,
): ConnectedComponent<React.ComponentType<P & IProps>, IProps> => {
  class WithEntriesTable extends React.Component<PropType & P> {
    // Get content data based on content type
    componentDidMount(): void {
      const { getContent, contentType, contentData } = this.props;

      getContent({ contentType, ...contentData.pagination });
    }

    handleChangeOrder = (property: string): (() => void) => {
      return (): void => {
        const {
          contentData: { pagination },
          setPagination,
          contentType,
        } = this.props;
        const { orderBy, order } = pagination;
        const isAsc = orderBy === property && order === OrderEnum.ASC;

        setPagination({
          contentType,
          ...pagination,
          order: isAsc ? OrderEnum.DESC : OrderEnum.ASC,
          orderBy: property,
        });
      };
    };

    handleChangePage = (page: number): void => {
      const {
        contentType,
        contentData: { pagination },
        setPagination,
      } = this.props;

      setPagination({ contentType, ...pagination, page });
    };

    handleChangeRowsPerPage = (size: number): void => {
      const {
        contentType,
        contentData: { pagination },
        setPagination,
      } = this.props;

      setPagination({
        contentType,
        ...pagination,
        size,
        page: 0,
      });
    };

    handlePreviewEntry =
      ({ screenshotPreviewUrl: previewUrl }: IHubspotSearchContent) =>
      (): void => {
        const {
          preview: { showPreview: pShowPreview, previewUrl: pPreviewUrl },
          togglePreview,
          setPreviewSpinner,
        } = this.props;
        const showPreview = !pShowPreview || pPreviewUrl !== previewUrl;
        if (showPreview && pPreviewUrl !== previewUrl) {
          setPreviewSpinner(true);
        }
        togglePreview({ showPreview, previewUrl });
      };

    handleCheckEntry =
      (index: number, entry: IHubspotSearchContent, id: string) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const {
          contentType,
          checkEntry,
          addSourceLanguage,
          removeSourceLanguage,
          addTargetLanguage,
          removeTargetLanguage,
          getPageLanguages,
          pageLanguages,
        } = this.props;
        const { sourceLanguages, availableTargetLanguages } = entry;

        checkEntry({ contentType, index, value: checked });
        if (contentType === ContentTypeEnum.PAGE && checked && !pageLanguages[id]) {
          getPageLanguages({
            pageIds: [id],
            index,
          });
        }

        if (availableTargetLanguages?.length) {
          if (checked) {
            addTargetLanguage({
              languages: availableTargetLanguages,
              contentType,
            });
          } else {
            removeTargetLanguage({
              languages: availableTargetLanguages,
              contentType,
            });
          }
        }

        if (sourceLanguages?.length || (pageLanguages[id] && pageLanguages[id].length)) {
          if (checked) {
            addSourceLanguage({
              languages: sourceLanguages || pageLanguages[id],
              contentType,
            });
          } else {
            removeSourceLanguage({
              languages: sourceLanguages || pageLanguages[id],
              contentType,
            });
          }
        }
      };

    // Check all entries and filter source languages
    checkAllEntries = (value: boolean) => (): void => {
      const {
        contentData,
        contentType,
        checkAllEntries,
        addSourceLanguage,
        removeSourceLanguage,
        addTargetLanguage,
        removeTargetLanguage,
      } = this.props;

      checkAllEntries({ contentType, value });

      if (value) {
        const source = this.getFlatLanguagesWithCount(contentData, LanguageTypeEnum.SOURCE, true);
        const target = this.getFlatLanguagesWithCount(contentData, LanguageTypeEnum.TARGET, true);
        if (source.count) {
          addSourceLanguage({
            ...source,
            contentType,
          });
        }
        if (target.count) {
          addTargetLanguage({
            ...target,
            contentType,
          });
        }
      } else {
        const source = this.getFlatLanguagesWithCount(contentData, LanguageTypeEnum.SOURCE);
        const target = this.getFlatLanguagesWithCount(contentData, LanguageTypeEnum.TARGET);
        if (source.count) {
          removeSourceLanguage({
            ...source,
            contentType,
          });
        }
        if (target.count) {
          removeTargetLanguage({
            ...target,
            contentType,
          });
        }
      }
    };

    getFlatLanguagesWithCount = (
      contentData: IContent,
      type: LanguageTypeEnum,
      filterChecked?: boolean,
    ): { languages: Array<string>; count: number } => {
      const { contentType, pageLanguages } = this.props;
      let languages = contentData?.data?.content || [];
      let count = 0;

      if (filterChecked) {
        languages = languages.filter((entry) => !entry.checked);
        count = languages.length;
      } else {
        count = contentData?.data?.numberOfElements || 0;
      }

      if (contentType === ContentTypeEnum.PAGE && type !== LanguageTypeEnum.SOURCE) {
        return this.getFlatPageLanguagesWithCount(languages);
      }

      count = 0;

      return {
        languages: languages
          .flatMap((entry) => {
            const sourceLanguages = entry.sourceLanguages?.length ? entry.sourceLanguages : pageLanguages[entry.id];

            if (sourceLanguages?.length > 0) {
              count = count + 1;
            }

            return LanguageTypeEnum.SOURCE ? sourceLanguages : entry.availableTargetLanguages;
          })
          .filter(Boolean),
        count,
      };
    };

    checkForCheckedEntries = (page?: Page<IHubspotSearchContent>): { indeterminate: boolean; checked: boolean } => {
      if (page) {
        const { numberOfElements } = page;
        const checkedLength = page.content.filter((content) => content.checked).length;

        return {
          indeterminate: checkedLength > 0 && numberOfElements > checkedLength,
          checked: numberOfElements > 0 && numberOfElements === checkedLength,
        };
      }
      return {
        indeterminate: false,
        checked: false,
      };
    };

    getFlatPageLanguagesWithCount = (
      languages: Array<IHubspotSearchContent>,
    ): { languages: Array<string>; count: number } => {
      const { pageLanguages, getPageLanguages } = this.props;
      const ids: Array<string> = [];
      let count = 0;
      const filteredPageLanguages = languages
        .flatMap((entry) => {
          if (pageLanguages[entry.id]) {
            count += pageLanguages[entry.id].length > 0 ? 1 : 0;
            return pageLanguages[entry.id];
          }
          ids.push(entry.id);
          return undefined;
        })
        .filter((language) => language !== undefined);

      if (ids.length > 0) {
        getPageLanguages({ index: -1, pageIds: ids });
      }
      return { languages: filteredPageLanguages.filter(Boolean) as string[], count };
    };

    render(): JSX.Element {
      return (
        <WrappedComponent
          {...this.props}
          handleChangeOrder={this.handleChangeOrder}
          handleChangePage={this.handleChangePage}
          handleCheckEntry={this.handleCheckEntry}
          handlePreviewEntry={this.handlePreviewEntry}
          checkAllEntries={this.checkAllEntries}
          checkForCheckedEntries={this.checkForCheckedEntries}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return connect(mapStateToProps, mapDispatchToProps)(WithEntriesTable as any);
};
