/** @jsx jsx */
import momentUtils from '@date-io/moment';
import { jsx } from '@emotion/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { Component, Fragment } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseButton, DateButton, InputDiv, InputLabel } from './Input.styled';

interface IProps {
  inputProps: FieldInputProps<{ [key: string]: string } | null | undefined, HTMLElement>;
  label: string;
}

interface IState {
  date: Moment | null;
  open: boolean;
}

type PropType = IProps & WithTranslation;

export class CustomDatepicker extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      date: null,
      open: false,
    };
  }

  componentDidMount(): void {
    const { onChange } = this.props.inputProps;
    onChange(null);
  }

  componentDidUpdate(previousProps: PropType, previousState: IState): void {
    const { date } = this.state;
    if (date && previousState.date !== date) {
      const now = moment();
      if (date.isBefore(now)) {
        date.hour(now.hour() + 1).minute(0);
      }
      this.props.inputProps.onChange(date.toDate());
    } else if (date === null) {
      this.props.inputProps.onChange(date);
    }
  }

  handleOpen = (): void => {
    this.setState({ open: true });
  };

  handleClose = (): void => {
    this.setState({ open: false });
  };

  handleClear = (): void => {
    this.setState({ date: null });
  };

  renderEndAdornment = (): JSX.Element => (
    <Fragment>
      {this.state.date && (
        <CloseButton>
          <IconButton size="small" onClick={this.handleClear}>
            <CloseIcon />
          </IconButton>
        </CloseButton>
      )}
      <DateButton>
        <IconButton size="small" onClick={this.handleOpen} data-testid="datePickerButton">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H9.375C9.15625 0 9 0.1875 9 0.375V2H5V0.375C5 0.1875 4.8125 0 4.625 0H3.375C3.15625 0 3 0.1875 3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM12.3125 14.5H1.6875C1.5625 14.5 1.5 14.4375 1.5 14.3125V5H12.5V14.3125C12.5 14.4375 12.4062 14.5 12.3125 14.5Z"
              fill="#555555"
            />
          </svg>
        </IconButton>
      </DateButton>
    </Fragment>
  );

  render(): JSX.Element {
    const { inputProps, label, t } = this.props;

    return (
      <InputDiv>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <InputLabel>{t(label)}</InputLabel>
          <DateTimePicker
            disableToolbar
            disablePast
            size="small"
            views={['date', 'hours']}
            ampm={false}
            margin="dense"
            inputVariant="outlined"
            {...inputProps}
            inputProps={{
              'data-testid': 'datePickerInput',
            }}
            InputProps={{
              endAdornment: this.renderEndAdornment(),
            }}
            open={this.state.open}
            value={this.state.date}
            onChange={(value): void => {
              this.setState({ date: (value as Moment).minutes(0) });
            }}
            onClose={this.handleClose}
            onAccept={this.handleClose}
          />
        </MuiPickersUtilsProvider>
      </InputDiv>
    );
  }
}

export default withTranslation()(CustomDatepicker);
