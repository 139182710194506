import styled, { StyledComponent } from '@emotion/styled';
import { Typography, TypographyProps, TypographyStyle } from '@material-ui/core';

export const NameTypography = (
  width: string,
  lines: number,
  textColor?: string,
): StyledComponent<TypographyProps, TypographyStyle, object> => styled(Typography)`
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: ${width} !important;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  color: ${textColor ? `${textColor} !important` : `#333 !important`};
  font-size: 13px !important;
`;
