export const SpinnerActionTypes = {
  setAuthSpinner: 'SPINNER_SET_AUTH_SPINNER',
  setXtmCustomerSpinner: 'SPINNER_SET_XTM_CUSTOMER_SPINNER',
  setXtmTemplateSpinner: 'SPINNER_SET_XTM_TEMPLATE_SPINNER',
  setXtmLanguagesSpinner: 'SPINNER_SET_XTM_LANGUAGES_SPINNER',
  setXtmActiveProjectsSpinner: 'SPINNER_SET_XTM_ACTIVE_PROJECTS_SPINNER',
  setJobsSpinner: 'SPINNER_SET_JOBS_SPINNER',
  toggleJobActionSpinner: 'SPINNER_TOGGLE_JOB_ACTION_SPINNER',
  setContentSpinner: 'SPINNER_SET_CONTENT_SPINNER',
  setTranslateSpinner: 'SPINNER_SEND_FOR_TRANSLATION',
  setPreviewSpinner: 'SPINNER_SET_PREVIEW_SPINNER',
};
