/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Logo } from 'components/XTMLogo/Logo';
import { StorageKeyEnum } from 'enums/StorageKeyEnum';
import { PureComponent, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch } from 'store';
import { logoutUser } from 'store/auth/auth.actions';
import { getEnvironment } from 'utils/handlers/envHandler';
import { getItem } from 'utils/handlers/storageHandler';
import HeaderStyle, { HeaderButton, HeaderContainer, HeaderLogoContainer, HeaderLogoutButton } from './Header.style';

interface IProps {
  children?: ReactNode;
}

interface IDispatchProps {
  logout: () => AppDispatch;
}

type PropType = IProps & WithTranslation & IDispatchProps;

class Header extends PureComponent<PropType> {
  handleOpenLink = (url: string) => (): void => {
    window.open(url, '_blank');
  };

  handleLogout = (): void => {
    this.props.logout();
  };

  render(): JSX.Element {
    const { t } = this.props;
    const helpUrl = getEnvironment('REACT_APP_HELP_URL');
    const changelogUrl = getEnvironment('REACT_APP_CHANGELOG_URL');

    return (
      <HeaderContainer position="relative">
        <HeaderLogoContainer>
          <Logo />
          <span css={HeaderStyle.title}>
            {t('common.title')} {t('common.version', { version: process.env.REACT_APP_VERSION })}
          </span>
        </HeaderLogoContainer>
        <div>
          {changelogUrl && (
            <HeaderButton data-testid="logout" disableTouchRipple onClick={this.handleOpenLink(changelogUrl)}>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 0.5H2C0.875 0.5 0 1.40625 0 2.5V11.5C0 12.625 0.875 13.5 2 13.5H5V16.125C5 16.4375 5.34375 16.625 5.59375 16.4375L9.5 13.5H14C15.0938 13.5 16 12.625 16 11.5V2.5C16 1.40625 15.0938 0.5 14 0.5ZM9 8.75C9 8.90625 8.875 9 8.75 9H4.25C4.09375 9 4 8.90625 4 8.75V8.25C4 8.125 4.09375 8 4.25 8H8.75C8.875 8 9 8.125 9 8.25V8.75ZM12 5.75C12 5.90625 11.875 6 11.75 6H4.25C4.09375 6 4 5.90625 4 5.75V5.25C4 5.125 4.09375 5 4.25 5H11.75C11.875 5 12 5.125 12 5.25V5.75Z"
                  fill="white"
                />
              </svg>
              {t('common.releaseNotes')}
            </HeaderButton>
          )}
          {helpUrl && (
            <HeaderButton data-testid="logout" disableTouchRipple onClick={this.handleOpenLink(helpUrl)}>
              <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.9375 0.53125C15.2188 0.625 11.8125 0.96875 9.71875 2.25C9.5625 2.34375 9.46875 2.5 9.46875 2.65625V14.0312C9.46875 14.4062 9.875 14.625 10.2188 14.4688C12.375 13.375 15.5 13.0625 17.0312 13C17.5625 12.9688 17.9688 12.5312 17.9688 12.0312V1.46875C18 0.9375 17.5 0.5 16.9375 0.53125ZM8.25 2.25C6.15625 0.96875 2.75 0.625 1.03125 0.53125C0.46875 0.5 0 0.9375 0 1.46875V12.0312C0 12.5312 0.40625 12.9688 0.9375 13C2.46875 13.0625 5.59375 13.375 7.75 14.4688C8.09375 14.625 8.5 14.4062 8.5 14.0312V2.65625C8.5 2.5 8.40625 2.34375 8.25 2.25Z"
                  fill="white"
                />
              </svg>
              {t('common.help')}
            </HeaderButton>
          )}
          {getItem(StorageKeyEnum.tokenStorageKey) && (
            <HeaderLogoutButton data-testid="logout" disableTouchRipple onClick={this.handleLogout}>
              <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.625 12.5C5.8125 12.5 6 12.3438 6 12.125V10.875C6 10.6875 5.8125 10.5 5.625 10.5H3C2.4375 10.5 2 10.0625 2 9.5V3.5C2 2.96875 2.4375 2.5 3 2.5H5.625C5.8125 2.5 6 2.34375 6 2.125V0.875C6 0.6875 5.8125 0.5 5.625 0.5H3C1.34375 0.5 0 1.84375 0 3.5V9.5C0 11.1562 1.34375 12.5 3 12.5H5.625ZM9.28125 3.03125L11.7188 5.25H5.75C5.3125 5.25 5 5.59375 5 6V7C5 7.4375 5.3125 7.75 5.75 7.75H11.7188L9.28125 10C8.96875 10.2812 8.96875 10.7812 9.28125 11.0625L9.96875 11.75C10.25 12.0312 10.7188 12.0625 11 11.75L15.75 7.03125C16.0625 6.75 16.0625 6.28125 15.75 5.96875L11.0312 1.28125C10.7188 0.96875 10.25 0.96875 9.96875 1.28125L9.28125 1.96875C8.96875 2.25 8.96875 2.75 9.28125 3.03125Z"
                  fill="#00A4E4"
                />
              </svg>
              {t('common.logout')}
            </HeaderLogoutButton>
          )}
        </div>
        {this.props.children}
      </HeaderContainer>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  logout: (): AppDispatch => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(withTranslation()(Header));
