import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthenticationResult, AxiosRestApplicationClient } from 'utils/restApplicationClient';
import { StorageKeyEnum } from '../enums/StorageKeyEnum';
import { getAllEnvironment } from './handlers/envHandler';
import { getItem, setItems } from './handlers/storageHandler';

const { tokenStorageKey, refreshTokenStorageKey, tokenTtlStorageKey } = StorageKeyEnum;

/**
 * request interceptor
 *
 * @param config - axios default configuration
 */
const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  let headers: AxiosRequestConfig['headers'];
  const accessToken = getItem(tokenStorageKey);
  const refreshToken = getItem(refreshTokenStorageKey);

  if (config.url && config.url.includes('refreshToken')) {
    headers = {
      Authorization: `${accessToken}`,
      'X-Authorization': `${refreshToken}`,
    };
  } else {
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  config.headers = headers;
  return config;
};

/**
 * response interceptor
 *
 * @param response - axios response
 */
const responseInterceptor = (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
  if (response.config.url === 'api/auth/user' || response.config.url === 'api/auth/refreshToken') {
    const { accessToken, refreshToken, ttl } = response.data as AuthenticationResult;

    setItems({
      [tokenStorageKey]: accessToken,
      [refreshTokenStorageKey]: refreshToken,
      [tokenTtlStorageKey]: String(ttl),
    });
  }

  return response;
};

const axiosInstance = Axios.create();

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor);

const api: AxiosRestApplicationClient = new AxiosRestApplicationClient('/', axiosInstance);

/**
 * Return an oAuth url
 *
 */
const oAuthUrlBuilder = (): string => {
  const {
    REACT_APP_HUBSPOT_CLIENT_ID: clientId,
    REACT_APP_HUBSPOT_OAUTH_SCOPE: scope,
    REACT_APP_HUBSPOT_REDIRECT_URI: redirectUri,
  } = getAllEnvironment();
  return `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
};

export { api, oAuthUrlBuilder };
export default axiosInstance;
