import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';

export const isLoggedInSelector = ({ auth: { isLoggedIn } }: AppState): boolean => isLoggedIn;

export const isDirtySelector = ({ auth: { isDirty } }: AppState): boolean => isDirty;

export const getIsLoggedInSelector = createSelector(isLoggedInSelector, (isLoggedIn) => isLoggedIn);

export const getIsDirtySelector = createSelector(isDirtySelector, (isDirty) => isDirty);
