import addcontent from './addcontent.json';
import common from './common.json';
import errormessage from './errormessage.json';
import loginForm from './loginForm.json';
import modal from './modal.json';
import queue from './queue.json';

const en = {
  translation: {
    queue,
    common,
    loginForm,
    modal,
    errormessage,
    addcontent,
  },
};

export default en;
