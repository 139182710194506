/* eslint-disable promise/prefer-await-to-callbacks */
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { clearSourceLanguages, clearTargetLanguages } from 'store/languages/languages.actions';
import { api } from 'utils/axiosInstance';
import { apiErrorHandler, notify } from 'utils/handlers/notificationHandler';
import { TranslateRequestDTO } from 'utils/restApplicationClientTypeOverrides';
import {
  clearCheckEntries,
  getContent,
  getContentError,
  getContentSuccess,
  sendForTranslation,
  sendForTranslationError,
  sendForTranslationSuccess,
  setPagination,
} from './content.actions';
import { ContentService } from './content.service';

const getContentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getContent.type, setPagination.type),
    mergeMap(({ payload }) =>
      from(ContentService.getContentFromApi(payload)).pipe(
        switchMap(({ data }) =>
          of(
            getContentSuccess({
              response: data,
              contentType: payload.contentType,
            }),
            clearSourceLanguages(payload.contentType),
            clearTargetLanguages(payload.contentType),
          ),
        ),
        catchError((error) => {
          apiErrorHandler(error);
          return of(getContentError(payload.contentType));
        }),
      ),
    ),
  );

const sendForTranslationEpic: Epic = (action$) =>
  action$.pipe(
    ofType(sendForTranslation.type),
    switchMap(({ payload }: { payload: TranslateRequestDTO }) =>
      from(api.translate(payload)).pipe(
        switchMap(() => {
          const { customerId, targetLanguages, sourceLanguage } = payload;
          notify('addcontent.success.sendForTranslation', 'success');
          return of(
            sendForTranslationSuccess({
              customerId,
              sourceLanguage,
              targetLanguages,
            }),
            clearCheckEntries(),
          );
        }),
        catchError((error) => {
          apiErrorHandler(error);
          return of(sendForTranslationError());
        }),
      ),
    ),
  );

export const contentEpics = combineEpics(getContentEpic, sendForTranslationEpic);
