import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
interface IButtonProps {
  opened: number;
}

export const ActionMenuHeaderMenuButton = styled(Button)`
  background-color: ${({ opened }: IButtonProps) => (opened ? '#d7dbdd' : 'transparent')} !important;

  &:not(:disabled) {
    color: ${({ opened }: IButtonProps) => (opened ? '#333' : '#555')} !important;
  }
`;
