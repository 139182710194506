/**
 * Returns whether objects are equal (shallow compare)
 *
 * @param objectA - Key - Value object
 *
 * @param objectB - Key - Value object
 *
 * @returns {boolean} Whether objects are equal
 */
export function isObjectEqual(objectA: Record<string, unknown>, objectB: Record<string, unknown>): boolean {
  const objectAProperties = Object.getOwnPropertyNames(objectA);
  const objectBProperties = Object.getOwnPropertyNames(objectB);

  if (objectAProperties.length !== objectBProperties.length) {
    return false;
  }

  return objectAProperties.reduce(
    (previousValue: boolean, currentValue: string) => objectA[currentValue] === objectB[currentValue] && previousValue,
    true,
  );
}

/**
 * Returns whether arrays are equal (shallow compare)
 *
 * @param arrayA - An array of values
 *
 * @param arrayB - An array of values
 *
 * @returns {boolean} Whether arrays are equal
 */
export function isArrayEqual(arrayA: Array<unknown>, arrayB: Array<unknown>): boolean {
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  arrayB.sort();
  arrayA.sort();
  return arrayA.reduce(
    (previousValue: boolean, currentValue: unknown, index: number) => currentValue === arrayB[index] && previousValue,
    true,
  );
}
