import styled from '@emotion/styled';
import { TableCell, TableContainer, TableRow } from '@material-ui/core';

export const EntriesTableSpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const EntriesTableRow = styled(TableRow)<{ active?: number }>`
  transition: all 0.2s ease-in-out;
  background-color: ${(props): string => (props?.active ? '#d9f1fc' : '#fff')};
`;

export const EntriesTableCell = styled(TableCell)`
  color: #333 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding-top: 0 !important;
  padding-bottom: 1px !important;
`;

export const EntriesTableCellName = styled(EntriesTableCell)`
  max-width: 15vw;
  min-width: 15vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EntriesTableContainer = styled(TableContainer)`
  max-height: calc(100% - 40px);
  padding-right: 6px;
`;

export const EntriesTableHeaderCell = styled(TableCell)`
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #6f8294 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;
