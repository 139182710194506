import { CircularProgress, InputBaseComponentProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { ChangeEvent, Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SearchInputTextField } from './SearchInput.styled';

interface IProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  spinner?: boolean;
  value: string;
}

class SearchInput extends PureComponent<IProps & WithTranslation> {
  render(): JSX.Element {
    const { value, onChange, spinner, t } = this.props;
    const error = value !== '' && !spinner && value?.length < 3;

    return (
      <Fragment>
        <SearchInputTextField
          color="primary"
          variant="outlined"
          margin="dense"
          fullWidth
          size="small"
          inputProps={
            {
              'data-testid': 'searchInput',
            } as InputBaseComponentProps
          }
          InputProps={{
            endAdornment: spinner ? <CircularProgress size={16} /> : <SearchIcon style={{ color: '#999' }} />,
          }}
          onChange={onChange}
          value={value}
          error={error}
          helperText={error && t('errormessage.general.searcherror')}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(SearchInput);
