import { Button, CircularProgress, IconButton, Modal, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomDialog from 'components/Dialog/CustomDialog';
import CustomDatepicker from 'components/Form/Input/CustomDatepicker';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { createForm, FormApi } from 'final-form';
import React, { Component, Fragment } from 'react';
import { Field, Form } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getDisabledCheckboxesSelector } from 'store/content/content.selectors';
import {
  getSourceLanguageCountSelector,
  getSourceLanguagesSelector,
  getTargetLanguageCountSelector,
  getTargetLanguagesSelector,
} from 'store/languages/languages.selectors';
import { togglePreview } from 'store/preview/preview.actions';
import { IPreviewState } from 'store/preview/preview.interface';
import { previewSelector } from 'store/preview/preview.selectors';
import {
  getActiveProjectsSelector,
  getDefaultSourceLanguage,
  getForceDefaultCustomerSettings,
  getXtmCustomerSelector,
  getXtmTemplateSelector,
} from 'store/settings/settings.selectors';
import { setPreviewSpinner, setTranslateSpinner } from 'store/spinner/spinner.actions';
import {
  getPreviewSpinnerSelector,
  getTranslateSpinnerSelector,
  getXtmActiveProjectsSpinnerSelector,
  getXtmCustomerSpinnerSelector,
  getXtmLanguagesSpinnerSelector,
  getXtmTemplateSpinnerSelector,
} from 'store/spinner/spinner.selectors';
import { Entry } from 'types/Entry';
import { api } from 'utils/axiosInstance';
import { required, requiredAutocomplete } from 'utils/customValidators';
import { apiErrorHandler } from 'utils/handlers/notificationHandler';
import { isArrayEqual } from 'utils/objectEquality';
import { XTMProjectDTO, XTMProjectEntriesVerifyDTO, XTMProjectVerifyDTO } from 'utils/restApplicationClient';
import { GetCustomersDTO, GetTemplatesDTO } from 'utils/restApplicationClientTypeOverrides';
import CustomField from '../Input/CustomField';
import CustomSelect from '../Input/CustomSelect';
import {
  AddContentFormButtonContainer,
  AddContentFormColumn,
  AddContentFormContainer,
  AddContentFormDiv,
  AddContentFormModalContent,
  AddContentFormModalSpinner,
  AddContentFormRow,
  AddContentPreviewBar,
  AddContentPreviewBox,
  AddContentPreviewContainer,
  AddContentPreviewSpinner,
} from './AddContentForm.styled';
import ContentTable from './ContentTable/ContentTable';
import { withAddContent, WithAddContent } from './HOC/WithAddContent';
import addContentParser from './HOC/WithAddContent.parser';

interface IStateProps {
  xtmCustomer?: GetCustomersDTO;
  xtmTemplate?: GetTemplatesDTO;
  activeProjects?: Array<XTMProjectDTO>;
  xtmCustomerSpinner: boolean;
  xtmTemplateSpinner: boolean;
  activeProjectsSpinner: boolean;
  languagesSpinner: boolean;
  translateSpinner: boolean;
  preview: IPreviewState;
  availableSourceLanguages: Array<string>;
  availableTargetLanguages: Array<string>;
  disabledCheckboxes: Array<number>;
  sourceLanguageCount: number;
  targetLanguageCount: number;
  previewSpinner: boolean;
  forceDefaultCustomerSettings: boolean;
  defaultSourceLanguage: string;
}

interface IState {
  addContentDisabled: boolean;
  modalOpen: boolean;
  forceRefresh: boolean;
  dialogOpen: boolean;
  entries: Entry[];
  verifyEntries: XTMProjectEntriesVerifyDTO[];
}

interface IDispatchProps {
  togglePreview: (payload: IPreviewState) => AppDispatch;
  setPreviewSpinner: (payload: boolean) => AppDispatch;
  setTranslateSpinner: (payload: boolean) => AppDispatch;
}

export interface IAddContentForm {
  projectName: string;
  xtmCustomer: { label: string; value: string };
  xtmTemplate: { label: string; value: string };
  sourceLanguage: { label: string; value: string };
  targetLanguages: Array<{ label: string; value: string }>;
  activeProject: { label: string; value: string };
  dueDate: string;
}

type PropType = WithTranslation & IDispatchProps & IStateProps & WithAddContent;

export class AddContentForm extends Component<PropType, IState> {
  private form: FormApi<IAddContentForm>;
  private parser: typeof addContentParser;

  constructor(props: PropType) {
    super(props);
    this.parser = addContentParser;
    this.form = createForm<IAddContentForm>({
      onSubmit: this.onSubmit,
    });
    this.state = {
      addContentDisabled: true,
      modalOpen: false,
      entries: [],
      forceRefresh: false,
      dialogOpen: false,
      verifyEntries: [],
    };
  }

  componentDidMount(): void {
    this.props.getCustomers();
  }

  componentDidUpdate({
    availableSourceLanguages: previousAvailableSourceLanguages,
    availableTargetLanguages: previousAvailableTargetLanguages,
  }: PropType): void {
    const { availableSourceLanguages, availableTargetLanguages } = this.props;

    if (!isArrayEqual(previousAvailableSourceLanguages, availableSourceLanguages)) {
      const { sourceLanguage } = this.form.getState().values;

      if (sourceLanguage && !availableSourceLanguages.includes(sourceLanguage.value)) {
        this.form.change('sourceLanguage', undefined);
      }
    }

    if (!isArrayEqual(previousAvailableTargetLanguages, availableTargetLanguages)) {
      const { targetLanguages } = this.form.getState().values;

      if (
        targetLanguages &&
        !availableTargetLanguages.filter((language) => targetLanguages.find(({ value }) => value === language)).length
      ) {
        this.form.change('targetLanguages', undefined);
      }
    }
  }

  private onSubmit = (): void => {
    const {
      xtmCustomer: { value: xtmCustomerId },
      xtmTemplate: { value: xtmTemplateId },
      sourceLanguage: { value: sourceLanguage },
      targetLanguages,
      activeProject,
    } = this.form.getState().values;

    const {
      contentState: { blogPostsState, formsState, mailsState, pagesState },
      parser,
    } = this.props;

    const verifyData: XTMProjectVerifyDTO = {
      customerId: Number(xtmCustomerId),
      templateId: Number(xtmTemplateId),
      sourceLanguage,
      targetLanguages: targetLanguages.map((language) => language.value),
      entries: parser.filterCheckedEntries({
        blogPostsState,
        formsState,
        mailsState,
        pagesState,
      }),
    };

    if (activeProject) {
      this.props.sendForTranslation(this.form.getState().values, undefined, activeProject.value);
      return;
    }

    const { setTranslateSpinner } = this.props;

    setTranslateSpinner(true);

    api
      .verify(verifyData)
      .then(({ data: { entries, haveProjects } }) => {
        this.setState({
          dialogOpen: haveProjects,
          verifyEntries: entries,
        });

        if (!haveProjects) {
          this.props.sendForTranslation(this.form.getState().values);
        } else {
          setTranslateSpinner(false);
        }
      })
      // eslint-disable-next-line promise/prefer-await-to-callbacks
      .catch((error) => {
        setTranslateSpinner(false);
        apiErrorHandler(error);
      });
  };

  handleDialogClose = (action: ButtonVariantEnum): void => {
    const formData = { ...this.form.getState().values };
    const { verifyEntries } = this.state;

    switch (action) {
      case ButtonVariantEnum.SEND: {
        this.props.sendForTranslation(formData);
        break;
      }
      case ButtonVariantEnum.UPDATE: {
        this.props.sendForTranslation(formData, verifyEntries);
        break;
      }
    }

    this.setState({
      dialogOpen: false,
    });
  };

  closePreview = () => (): void => {
    const { togglePreview } = this.props;
    togglePreview({ showPreview: false });
  };

  handleOnLoadPreview = () => (): void => {
    const { setPreviewSpinner } = this.props;
    setPreviewSpinner(false);
  };

  render(): JSX.Element {
    const {
      t,
      xtmCustomer,
      xtmTemplate,
      defaultSourceLanguage,
      sourceLanguages,
      targetLanguages,
      activeProjects,
      xtmCustomerSpinner,
      xtmTemplateSpinner,
      activeProjectsSpinner,
      languagesSpinner,
      translateSpinner,
      getDefaultCustomer,
      getDefaultTemplate,
      getDefaultLanguage,
      handleCustomerChange,
      handleTemplateChange,
      handleSourceLanguageChange,
      handleTargetLanguagesChange,
      handleActiveProjectChange,
      availableSourceLanguages,
      availableTargetLanguages,
      disabledCheckboxes,
      sourceLanguageCount,
      targetLanguageCount,
      forceDefaultCustomerSettings,
      contentState: { blogPostsState, formsState, mailsState, pagesState },
      previewSpinner,
    } = this.props;
    const { modalOpen, dialogOpen } = this.state;
    const parsed = {
      customers: this.parser.parseCustomers(xtmCustomer),
      templates: this.parser.parseTemplates(xtmTemplate),
      sourceLanguages: this.parser.parseLanguages(sourceLanguages, availableSourceLanguages, sourceLanguageCount),
      targetLanguages: this.parser.parseLanguages(targetLanguages, availableTargetLanguages, targetLanguageCount),
      activeProjects: this.parser.parseProjects(activeProjects),
    };

    const defaultValues = {
      customer: getDefaultCustomer(xtmCustomer, parsed.customers),
      template: getDefaultTemplate(xtmTemplate, parsed.templates),
      sourceLanguage: getDefaultLanguage(defaultSourceLanguage, parsed.sourceLanguages),
    };

    if (this.form.getFieldState('sourceLanguage')?.value) {
      parsed.targetLanguages = parsed.targetLanguages.filter(
        ({ value }) => defaultValues.sourceLanguage?.value !== value,
      );
    }

    if (forceDefaultCustomerSettings) {
      const { customer, template } = defaultValues;

      parsed.customers = customer ? new Array(customer) : parsed.customers;
      parsed.templates = template ? new Array(template) : parsed.templates;
    }

    const { showPreview, previewUrl } = this.props.preview;
    const checkedEntries = this.parser.filterCheckedEntries({
      blogPostsState,
      formsState,
      mailsState,
      pagesState,
    });
    return (
      <AddContentFormDiv>
        <Modal
          open={modalOpen}
          disableBackdropClick
          disableAutoFocus
          disableEnforceFocus
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255,255,0.5)' },
          }}
        >
          <AddContentFormModalContent>
            <div>
              <AddContentFormModalSpinner>
                <CircularProgress color="secondary" size={60} />
              </AddContentFormModalSpinner>
            </div>
          </AddContentFormModalContent>
        </Modal>
        <CustomDialog onClose={this.handleDialogClose} open={dialogOpen} variant={DialogVariantEnum.IN_TRANSLATION} />
        <Form
          onSubmit={this.onSubmit}
          subscription={{ pristine: true, submitting: true, values: true }}
          form={this.form}
          render={({ handleSubmit, values }): JSX.Element => (
            <AddContentFormContainer onSubmit={handleSubmit}>
              <AddContentFormRow>
                <AddContentFormColumn>
                  <Field validate={required} name="projectName">
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label={'addcontent.projectName'}
                        inputProps={input}
                        meta={meta}
                        textFieldProps={{
                          disabled: Boolean(values?.activeProject),
                        }}
                      />
                    )}
                  </Field>
                  <Field name="xtmCustomer" initialValue={defaultValues.customer} validate={requiredAutocomplete}>
                    {({ input, meta }): JSX.Element => (
                      <CustomSelect
                        label={'addcontent.xtmCustomer'}
                        options={parsed.customers}
                        inputProps={input}
                        onChange={handleCustomerChange(this.form)}
                        meta={meta}
                        spinner={xtmCustomerSpinner}
                        testId="xtmCustomerInput"
                        placeholder="common.defaultPlaceholder"
                      />
                    )}
                  </Field>
                  <Field name="xtmTemplate" initialValue={defaultValues.template} validate={requiredAutocomplete}>
                    {({ input, meta }): JSX.Element => (
                      <CustomSelect
                        label={'addcontent.xtmTemplate'}
                        options={parsed.templates}
                        inputProps={input}
                        onChange={handleTemplateChange(this.form)}
                        meta={meta}
                        spinner={xtmTemplateSpinner}
                        testId="xtmTemplateInput"
                        placeholder="common.defaultPlaceholder"
                      />
                    )}
                  </Field>
                  <Field
                    name="sourceLanguage"
                    initialValue={defaultValues.sourceLanguage}
                    validate={requiredAutocomplete}
                  >
                    {({ input, meta }): JSX.Element => (
                      <CustomSelect
                        label={'addcontent.sourceLanguage'}
                        options={parsed.sourceLanguages}
                        inputProps={input}
                        onChange={handleSourceLanguageChange(this.form)}
                        meta={meta}
                        spinner={languagesSpinner}
                        testId="sourceLanguageInput"
                        noOptionsText={t('addcontent.sourceLanguagesListEmpty')}
                      />
                    )}
                  </Field>
                  <Field name="targetLanguages" validate={requiredAutocomplete}>
                    {({ input, meta }): JSX.Element => (
                      <CustomSelect
                        label={'addcontent.targetLanguages'}
                        options={parsed.targetLanguages}
                        multiple
                        inputProps={input}
                        onChange={handleTargetLanguagesChange(this.form)}
                        meta={meta}
                        spinner={languagesSpinner}
                        testId="targetLanguagesInput"
                        noOptionsText={t('addcontent.sourceLanguagesListEmpty')}
                      />
                    )}
                  </Field>
                  <Field name="activeProject">
                    {({ input, meta }): JSX.Element => (
                      <CustomSelect
                        label={'addcontent.activeXtmProjects'}
                        options={parsed.activeProjects}
                        inputProps={input}
                        meta={meta}
                        spinner={activeProjectsSpinner}
                        onChange={handleActiveProjectChange(this.form)}
                        placeholder="common.defaultPlaceholder"
                      />
                    )}
                  </Field>
                  <Field name="dueDate">
                    {({ input }): JSX.Element => <CustomDatepicker label={'addcontent.dueDate'} inputProps={input} />}
                  </Field>
                </AddContentFormColumn>
                <AddContentFormColumn>
                  <ContentTable />
                </AddContentFormColumn>
                <AddContentFormColumn style={{ flex: showPreview ? '2' : '0' }}>
                  <AddContentPreviewContainer elevation={6}>
                    <AddContentPreviewBar>
                      <Tooltip title={<Fragment>{t('addcontent.hidePreview')}</Fragment>}>
                        <IconButton size="small" onClick={this.closePreview()}>
                          <HighlightOffIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </AddContentPreviewBar>
                    <AddContentPreviewBox>
                      <AddContentPreviewSpinner style={{ opacity: previewSpinner ? '1' : '0' }}>
                        <CircularProgress size="96px" color="secondary" />
                      </AddContentPreviewSpinner>
                      <img
                        src={previewUrl}
                        style={{ opacity: showPreview ? '1' : '0' }}
                        onLoad={this.handleOnLoadPreview()}
                      ></img>
                    </AddContentPreviewBox>
                  </AddContentPreviewContainer>
                </AddContentFormColumn>
              </AddContentFormRow>

              <AddContentFormButtonContainer>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  data-testid="buttonSendForTranslation"
                  color="secondary"
                  disabled={!!!checkedEntries.length || translateSpinner || disabledCheckboxes.length > 0}
                >
                  {translateSpinner ? (
                    <CircularProgress size="24px" color="secondary" />
                  ) : (
                    t('addcontent.sendForTranslation')
                  )}
                </Button>
              </AddContentFormButtonContainer>
            </AddContentFormContainer>
          )}
        />
      </AddContentFormDiv>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  xtmCustomer: getXtmCustomerSelector(state),
  xtmTemplate: getXtmTemplateSelector(state),
  activeProjects: getActiveProjectsSelector(state),
  xtmCustomerSpinner: getXtmCustomerSpinnerSelector(state),
  xtmTemplateSpinner: getXtmTemplateSpinnerSelector(state),
  languagesSpinner: getXtmLanguagesSpinnerSelector(state),
  translateSpinner: getTranslateSpinnerSelector(state),
  activeProjectsSpinner: getXtmActiveProjectsSpinnerSelector(state),
  preview: previewSelector(state),
  availableSourceLanguages: getSourceLanguagesSelector(state) as Array<string>,
  availableTargetLanguages: getTargetLanguagesSelector(state) as Array<string>,
  disabledCheckboxes: getDisabledCheckboxesSelector(state),
  sourceLanguageCount: getSourceLanguageCountSelector(state),
  targetLanguageCount: getTargetLanguageCountSelector(state),
  previewSpinner: getPreviewSpinnerSelector(state),
  forceDefaultCustomerSettings: getForceDefaultCustomerSettings(state),
  defaultSourceLanguage: getDefaultSourceLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  togglePreview: (payload: IPreviewState): AppDispatch => dispatch(togglePreview(payload)),
  setPreviewSpinner: (payload: boolean): AppDispatch => dispatch(setPreviewSpinner(payload)),
  setTranslateSpinner: (payload: boolean): AppDispatch => dispatch(setTranslateSpinner(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAddContent(withTranslation()(AddContentForm)));
