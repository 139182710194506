export const SettingsActionTypes = {
  GetCustomers: 'GET_CUSTOMERS',
  GetCustomersSuccess: 'GET_CUSTOMERS_SUCCESS',
  GetCustomersError: 'GET_CUSTOMERS_ERROR',
  GetTemplatesByCustomerId: 'GET_TEMPLATES_BY_CUSTOMER_ID',
  GetTemplatesByCustomerIdSuccess: 'GET_TEMPLATES_BY_CUSTOMER_ID_SUCCESS',
  GetTemplatesByCustomerIdError: 'GET_TEMPLATES_BY_CUSTOMER_ID_ERROR',
  ImportUserSettings: 'IMPORT_USER_SETTINGS',
  ImportUserSettingsSuccess: 'IMPORT_USER_SETTINGS_SUCCESS',
  ImportUserSettingsError: 'IMPORT_USER_SETTINGS_ERROR',
  GetActiveProjects: 'GET_ACTIVE_PROJECTS',
  GetActiveProjectsSuccess: 'GET_ACTIVE_PROJECTS_SUCCESS',
  GetActiveProjectsError: 'GET_ACTIVE_PROJECTS_ERROR',
  ClearSettings: 'CLEAR_SETTINGS',
  ClearLanguagesActiveProjects: 'CLEAR_LANGUAGES_ACTIVE_PROJECTS',
  ClearActiveProjects: 'CLEAR_ACTIVE_PROJECTS',
};
