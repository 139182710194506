import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DialogVariantEnum } from 'enums/dialogVariant';
import React, { Component, Fragment, MouseEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { XTMJobDTO } from 'utils/restApplicationClient';
import { ActionMenuHeaderMenuButton } from './ActionMenu.styled';

interface IState {
  anchorEl: Element | null;
}

export interface IActionMenuProps {
  operation?: DialogVariantEnum;
  onDialogAction: (jobIds: string[], operation: DialogVariantEnum, isMassAction?: boolean) => () => void;
  job?: XTMJobDTO;
  selectedJobs?: XTMJobDTO[];
  disabled?: boolean;
  headerMenu?: boolean;
}

type PropType = IActionMenuProps & WithTranslation;

class ActionMenu extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event: MouseEvent): void => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleButtonClose = () => (): void => {
    const { headerMenu, onDialogAction, operation } = this.props;
    const multipleIds: string[] = this.props?.selectedJobs?.map(({ id }) => id) || [''];
    const singleIds: string[] = this.props?.job?.id ? [this.props?.job?.id] : [''];
    const jobIds: string[] = headerMenu ? multipleIds : singleIds;

    if (operation) {
      this.setState({ anchorEl: null });

      onDialogAction(jobIds, operation, !!headerMenu)();
    }
  };

  handleClose = (): void => {
    this.setState({ anchorEl: null });
  };

  render(): JSX.Element {
    const { anchorEl } = this.state;
    const { t, operation, disabled, headerMenu } = this.props;
    const tooltipLabel = headerMenu ? (disabled ? t('queue.notChecked') : '') : t('queue.actions');

    return (
      <Fragment>
        <Tooltip title={tooltipLabel}>
          <div>
            {headerMenu ? (
              <ActionMenuHeaderMenuButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                disabled={disabled}
                onClick={this.handleClick}
                opened={!!anchorEl ? 1 : 0}
              >
                <Box fontWeight="500" fontSize="14px">
                  {t('queue.actions')}
                </Box>
                <ArrowDropDown fontSize="default" />
              </ActionMenuHeaderMenuButton>
            ) : (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="small"
                disabled={disabled}
                onClick={this.handleClick}
              >
                <MoreVertIcon fontSize="default" />
              </IconButton>
            )}
          </div>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={
            headerMenu ? { vertical: 'bottom', horizontal: 'left' } : { vertical: 'center', horizontal: 'right' }
          }
          transformOrigin={
            headerMenu ? { vertical: 'top', horizontal: 'left' } : { vertical: 'center', horizontal: 'right' }
          }
        >
          <MenuItem disabled={operation !== DialogVariantEnum.IMPORT} onClick={this.handleButtonClose()}>
            {t('queue.import')}
          </MenuItem>

          <MenuItem disabled={operation !== DialogVariantEnum.CANCEL} onClick={this.handleButtonClose()}>
            {t('queue.cancel')}
          </MenuItem>

          <MenuItem disabled={operation !== DialogVariantEnum.DELETE} onClick={this.handleButtonClose()}>
            {t('queue.delete')}
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default withTranslation()(ActionMenu);
