import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { ISpinnerState } from './spinner.interface';

const spinnerStateSelector = ({ spinner }: AppState): ISpinnerState => spinner;

export const getAuthSpinnerSelector = createSelector(spinnerStateSelector, ({ authSpinner }) => authSpinner);

export const getXtmCustomerSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ xtmCustomerSpinner }) => xtmCustomerSpinner,
);

export const getXtmTemplateSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ xtmTemplateSpinner }) => xtmTemplateSpinner,
);

export const getXtmLanguagesSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ xtmLanguagesSpinner }) => xtmLanguagesSpinner,
);

export const getXtmActiveProjectsSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ xtmActiveProjectsSpinner }) => xtmActiveProjectsSpinner,
);

export const getJobsSpinnerSelector = createSelector(spinnerStateSelector, ({ jobsSpinner }) => jobsSpinner);

export const getJobActionSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ jobActionSpinner }) => jobActionSpinner,
);

export const getContentSpinnerSelector = createSelector(spinnerStateSelector, ({ contentSpinner }) => contentSpinner);

export const getTranslateSpinnerSelector = createSelector(
  spinnerStateSelector,
  ({ translateSpinner }) => translateSpinner,
);

export const getPreviewSpinnerSelector = createSelector(spinnerStateSelector, ({ previewSpinner }) => previewSpinner);
