import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IJobsState } from './jobs.interface';

export const jobsStateSelector = ({ jobs }: AppState): IJobsState => jobs;

export const getJobsSelector = createSelector(jobsStateSelector, ({ jobs }: IJobsState) => jobs);

export const getOpenedDialogsSelector = createSelector(
  jobsStateSelector,
  ({ openedDialogs }: IJobsState) => openedDialogs,
);

export const getSimilarIdsSelector = createSelector(jobsStateSelector, ({ similarIds }: IJobsState) => similarIds);

export const getSelectedJobsSelector = createSelector(
  jobsStateSelector,
  ({ selectedJobs }: IJobsState) => selectedJobs,
);

export const getJobsPaginationSelector = createSelector(jobsStateSelector, ({ pagination }) => pagination);
