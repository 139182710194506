import { Box, Button, DialogActions, Divider } from '@material-ui/core';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomDialogButtonContainer, CustomDialogContent, CustomDialogContentText } from '../CustomDialog.styled';

interface IProps {
  onClose: (response: ButtonVariantEnum) => void;
}

type PropType = IProps & WithTranslation;

class InTranslation extends Component<PropType> {
  private handleClose = (reason: ButtonVariantEnum) => (): void => {
    this.props.onClose(reason);
  };

  render(): JSX.Element {
    const { t } = this.props;
    return (
      <Fragment>
        <CustomDialogContent>
          <CustomDialogContentText>
            {t('modal.inTranslationAlert1')}
            <br />
            {t('modal.inTranslationAlert2')}
          </CustomDialogContentText>
        </CustomDialogContent>
        <Divider />
        <DialogActions>
          <CustomDialogButtonContainer>
            <Button color="secondary" variant="contained" onClick={this.handleClose(ButtonVariantEnum.SEND)}>
              <Box fontSize="13px" fontWeight="500">
                {t('modal.createNew')}
              </Box>
            </Button>
            <Button variant="outlined" onClick={this.handleClose(ButtonVariantEnum.UPDATE)}>
              <Box fontSize="13px" fontWeight="500" color="#333">
                {t('modal.update')}
              </Box>
            </Button>
            <Button variant="outlined" onClick={this.handleClose(ButtonVariantEnum.QUIT)}>
              <Box fontSize="13px" fontWeight="500" color="#333">
                {t('modal.cancel')}
              </Box>
            </Button>
          </CustomDialogButtonContainer>
        </DialogActions>
      </Fragment>
    );
  }
}

export default withTranslation()(InTranslation);
