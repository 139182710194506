import AddContentForm from 'components/Form/AddContent/AddContentForm';
import LoginForm from 'components/Form/Login/LoginForm';
import Home from 'components/Home/Home';
import TranslationQueue from 'components/TranslationQueue/TranslationQueue';
import { RouterUrlEnum } from 'enums/routerUrl';
import { IRoute } from 'types/Router';

export const MainRoutes: IRoute[] = [
  {
    pathname: RouterUrlEnum.LOGIN,
    privateRoute: false,
    restricted: true,
    component: LoginForm,
  },
  {
    pathname: RouterUrlEnum.HOME,
    privateRoute: true,
    component: Home,
  },
];

export const TrustedRoutes: IRoute[] = [
  {
    pathname: RouterUrlEnum.TRANSLATION,
    privateRoute: true,
    exact: true,
    component: TranslationQueue,
  },
  {
    pathname: RouterUrlEnum.CONTENT,
    privateRoute: true,
    exact: true,
    component: AddContentForm,
  },
];
