/* eslint-disable promise/prefer-await-to-callbacks */
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, from, of, timer } from 'rxjs';
import { catchError, delayWhen, pluck, switchMap } from 'rxjs/operators';
import { api, oAuthUrlBuilder } from 'utils/axiosInstance';
import { apiErrorHandler } from 'utils/handlers/notificationHandler';
import { clearToken } from 'utils/handlers/tokenHandler';
import { AuthenticationResult } from 'utils/restApplicationClient';
import {
  login,
  loginError,
  loginSuccess,
  logoutUser,
  logoutUserSuccess,
  oAuthConnect,
  oAuthConnectError,
  oAuthConnectSuccess,
  oAuthRefreshError,
  oAuthRefreshSuccess,
  refreshToken,
  refreshTokenSuccess,
  verifyTokenSuccess,
} from './auth.actions';

const loginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(login.type),
    switchMap(({ payload }) =>
      from(api.authenticateUser(payload)).pipe(
        switchMap(({ data }) => {
          return of(loginSuccess(data));
        }),
        catchError((error) => {
          if (error.response?.data?.errorCode) {
            return of(
              loginError({
                key: 'login',
                message: error.response.data.errorCode,
              }),
            );
          } else {
            return of(loginError({ key: 'login', message: 'errormessage.apiUrlError' }));
          }
        }),
      ),
    ),
  );

const oAuthRefreshEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginSuccess.type, verifyTokenSuccess.type),
    switchMap(({ payload }) =>
      from(api.refresh()).pipe(
        switchMap(() => {
          return of(oAuthRefreshSuccess(payload));
        }),
        catchError(() => {
          return of(oAuthRefreshError());
        }),
      ),
    ),
  );

const oAuthLoginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(oAuthRefreshError.type),
    switchMap(() => {
      window.location.href = oAuthUrlBuilder();
      return EMPTY;
    }),
  );

const oAuthConnectEpic: Epic = (action$, _, { history }) =>
  action$.pipe(
    ofType(oAuthConnect.type),
    switchMap(({ payload }) =>
      from(api.connect({ code: payload })).pipe(
        switchMap(() => of(oAuthConnectSuccess())),
        catchError((error) => {
          history.push('/login');
          apiErrorHandler(error);
          return of(oAuthConnectError());
        }),
      ),
    ),
  );

const refreshTokenEpic: Epic = (action$) =>
  action$.pipe(
    ofType(refreshTokenSuccess.type, oAuthRefreshSuccess.type),
    pluck('payload'),
    delayWhen(({ ttl = 890 }: AuthenticationResult) => timer((ttl - 10) * 1000)),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => of(refreshTokenSuccess(data))),
        catchError(() => of(logoutUser())),
      ),
    ),
  );

const verifyTokenEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(refreshToken.type, oAuthConnectSuccess.type),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => {
          return of(verifyTokenSuccess(data));
        }),
        catchError(() => {
          return of(logoutUser());
        }),
      ),
    ),
  );

const logoutUserEpic: Epic = (action$) =>
  action$.pipe(
    ofType(logoutUser.type, oAuthConnectError.type),
    switchMap(() => {
      clearToken();
      return of(logoutUserSuccess());
    }),
  );

export const authEpics = combineEpics(
  loginEpic,
  logoutUserEpic,
  refreshTokenEpic,
  verifyTokenEpic,
  oAuthRefreshEpic,
  oAuthLoginEpic,
  oAuthConnectEpic,
);
