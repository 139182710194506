/* eslint-disable promise/prefer-await-to-callbacks */
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IStoreState } from 'store';
import {
  importUserSettings,
  importUserSettingsError,
  importUserSettingsSuccess,
} from 'store/settings/settings.actions';
import { api } from 'utils/axiosInstance';
import { QueryJobsRequestParameters } from 'utils/restApplicationClientTypeOverrides';
import { apiErrorHandler } from '../../utils/handlers/notificationHandler';
import {
  clearSelectedJobs,
  getSimilarOperationIds,
  getSimilarOperationIdsError,
  getSimilarOperationIdsSuccess,
  performOperation,
  performOperationError,
  performOperationSuccess,
  queryJobs,
  queryJobsError,
  queryJobsSuccess,
  setJobsPagination,
  setOpenedDialogs,
} from './jobs.actions';

const jobsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(queryJobs.type, setJobsPagination.type),
    switchMap(({ payload }) => {
      const { forceRefresh, hubspotProjectId, search, page, size, sort } = payload;
      const queryParameters: QueryJobsRequestParameters = {
        forceRefresh,
        hubspotProjectId,
        search,
        page,
        size,
        sort,
      };

      return from(api.findAllPaged(queryParameters)).pipe(
        switchMap(({ data }) => {
          return of(clearSelectedJobs(), queryJobsSuccess(data));
        }),
        catchError((error) => {
          apiErrorHandler(error);

          return of(queryJobsError());
        }),
      );
    }),
  );

const similarOperationIds: Epic = (action$) =>
  action$.pipe(
    ofType(getSimilarOperationIds),
    switchMap(({ payload }) =>
      from(api.findJobsIdsByOperationType(payload)).pipe(
        switchMap((response) => {
          return of(getSimilarOperationIdsSuccess(response));
        }),
        catchError((error) => {
          apiErrorHandler(error);

          return of(getSimilarOperationIdsError());
        }),
      ),
    ),
  );

const importConfiguration: Epic = (action$) =>
  action$.pipe(
    ofType(importUserSettings),
    switchMap(() => {
      return from(api.importConfiguration()).pipe(
        switchMap(({ data }) => {
          return of(importUserSettingsSuccess(data));
        }),
        catchError((error) => {
          apiErrorHandler(error);

          return of(importUserSettingsError());
        }),
      );
    }),
  );

const performOperationEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(performOperation),
    switchMap(({ payload }) => {
      return from(api.performOperation(payload)).pipe(
        switchMap(() => {
          const {
            jobs: { pagination },
          } = store$.value as IStoreState;

          return of(performOperationSuccess(), setJobsPagination({ ...pagination, forceRefresh: true }));
        }),
        catchError((error) => {
          apiErrorHandler(error);
          return of(performOperationError());
        }),
      );
    }),
  );

const performOperationEndEpic: Epic = (action$) =>
  action$.pipe(
    ofType(performOperationSuccess, performOperationError),
    switchMap(() => {
      return of(setOpenedDialogs([]));
    }),
  );

export const jobsEpics = combineEpics(
  jobsEpic,
  similarOperationIds,
  performOperationEpic,
  performOperationEndEpic,
  importConfiguration,
);
