import { StorageKeyEnum } from '../../enums/StorageKeyEnum';
import { StorageData } from '../../types/StorageData';

/**
 *  Return an item assigned to given key from local storage
 *
 * @param key - Local storage key
 *
 */
export const getItem = (key: StorageKeyEnum): string | null => {
  return localStorage.getItem(key);
};

/**
 * Set value of item assigned to given key in local storage
 *
 * @param key - Local storage key
 *
 * @param value - New value
 *
 */
export const setItem = (key: StorageKeyEnum, value: string): void => {
  localStorage.setItem(key, value);
};

/**
 * Remove an item assigned to given key from local storage
 *
 * @param key - Local storage key
 */
export const removeItem = (key: StorageKeyEnum): void => {
  localStorage.removeItem(key);
};

/**
 * Set values for multiple local storage keys
 *
 * @param data - An object composed of key: string values
 */
export const setItems = (data: StorageData): void => {
  Object.keys(data).forEach((key) => {
    const value = data[key as keyof StorageData];
    if (value) {
      setItem(key as keyof StorageData, value);
    }
  });
};

/**
 * Remove items for multiple local storage keys
 *
 * @param keys - An array of local storage keys
 */
export const removeItems = (keys: Array<keyof StorageData>): void => {
  keys.forEach((key) => removeItem(key));
};
