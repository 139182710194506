/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CircularProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Autocomplete } from '@material-ui/lab';
import Name from 'components/Name/Name';
import React, { Fragment, ReactNode } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomEndAdornment, InputDiv, InputLabel } from './Input.styled';

interface IProps {
  options: Array<{ label: string; value: string }>;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  multiple?: boolean;
  inputProps: FieldInputProps<
    { label: string; value: string } | Array<{ label: string; value: string }> | null | undefined,
    HTMLElement
  >;
  label: string;
  noOptionsText?: string;
  placeholder?: string;
  error?: string;
  meta: { error?: string; touched?: boolean };
  testId?: string;
  spinner?: boolean;
  disabled?: boolean;
  fullscreen?: boolean;
}

type PropType = IProps & WithTranslation;

export class CustomSelect extends React.Component<PropType> {
  handleChange = (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ): void => {
    const { onChange, inputProps } = this.props;

    if (onChange) {
      onChange(event, value);
    }
    inputProps.onChange(value);
  };

  renderAdornment = (endAdornment: ReactNode): JSX.Element => {
    const { spinner, error, t } = this.props;

    if (spinner) {
      return (
        <CustomEndAdornment>
          <CircularProgress size={12} />
        </CustomEndAdornment>
      );
    }
    return (
      <Fragment>
        {error && (
          <Tooltip title={<Fragment>{t(error)}</Fragment>}>
            <ErrorIcon color="error" />
          </Tooltip>
        )}
        {endAdornment}
      </Fragment>
    );
  };

  render(): JSX.Element {
    const {
      options,
      multiple,
      inputProps,
      label,
      t,
      meta,
      testId,
      spinner,
      disabled,
      fullscreen,
      noOptionsText,
      placeholder,
    } = this.props;

    return (
      <InputDiv>
        <InputLabel>{t(label)}</InputLabel>
        <Autocomplete
          disabled={disabled || spinner}
          multiple={multiple}
          options={options}
          noOptionsText={!options.length ? noOptionsText : undefined}
          getOptionLabel={(option): string => (option.label ? option.label : '')}
          onChange={this.handleChange}
          getOptionSelected={(option, value): boolean => value.label === option.label || !multiple}
          value={inputProps.value ? inputProps.value : []}
          renderInput={(parameters): JSX.Element => (
            <TextField
              {...parameters}
              inputProps={{
                ...parameters.inputProps,
                'data-testid': testId,
              }}
              placeholder={placeholder ? t(placeholder) : ''}
              variant="outlined"
              margin="dense"
              error={Boolean(meta.error && meta.touched)}
              InputProps={{
                ...parameters.InputProps,
                endAdornment: this.renderAdornment(parameters.InputProps.endAdornment),
              }}
            />
          )}
          renderOption={(option): JSX.Element => (
            <Name text={option.label} width={fullscreen ? 880 : 312} maxLines={1} />
          )}
          size="small"
        />
        {meta.error && meta.touched && (
          <Typography variant="caption" color="error" component="div">
            {t(meta.error)}
          </Typography>
        )}
      </InputDiv>
    );
  }
}

export default withTranslation()(CustomSelect);
