import styled from '@emotion/styled';
import { DialogContent, DialogContentText } from '@material-ui/core';

export const CustomDialogButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  > button {
    margin: 0 8px;
  }
  justify-content: flex-end;
`;

export const CustomDialogContent = styled(DialogContent)`
  padding-top: 0 !important;
  min-width: 240px !important;
`;

export const CustomDialogContentText = styled(DialogContentText)`
  font-size: 13px !important;
  color: #333 !important;
`;
