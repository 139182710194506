import styled from '@emotion/styled';

export const JobProgress = styled.div<{ isSuccess: boolean }>`
  vertical-align: middle;
  color: #333;
  font-size: 13px !important;

  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    height: 5px;
    width: 50px;
  }

  progress[value]::-webkit-progress-bar {
    background: #f1f1f1;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }
  progress::-webkit-progress-value {
    background: ${(props: { isSuccess: boolean }) => (props.isSuccess ? `#38AF49` : `#00A4E4`)};
  }
`;
