import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const LoginFormTopBar = styled.div`
  background-color: #dce5ed;
  padding: 8px 24px;
`;

export const LoginFormLinkDiv = styled.div`
  margin-top: 12px;
  > a p {
    font-weight: bold !important;
  }
  text-align: start;
  width: 100%;
`;

export const LoginFormLinkDivBottom = styled.div`
  text-align: center;
  margin: 40px;
  position: absolute;
  font-size: 12;
  bottom: 0;
  width: auto;
`;

export const LoginFormErrorTypography = styled(Typography)`
  max-width: 400px;
`;

export const LoginFormHeaderTypography = styled(Typography)`
  margin-bottom: 50px;
`;

export const CursorPointer = css`
  cursor: pointer;
`;

export const LoginFormContainer = styled.div`
  width: 450px;
  border: 2px solid rgba(120, 120, 120, 0.2);
  border-radius: 4px;
  background-color: white;
`;
