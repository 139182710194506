import { createAction } from '@reduxjs/toolkit';
import { GenericAxiosResponse } from 'axios';
import { ContentTypeEnum } from 'enums/contentType';
import { HubspotLanguageDTO, PageLanguageVariationsDTO } from '../../utils/restApplicationClient';
import { LanguagesActionTypes } from './languages.actions.types';
import { IGetPageLanguages, IUpdateLanguages } from './languages.interface';

export const getAvailableLanguageList = createAction(LanguagesActionTypes.GetAvailableLanguageList);

export const getAvailableLanguageListSuccess = createAction<GenericAxiosResponse<HubspotLanguageDTO[]>>(
  LanguagesActionTypes.GetAvailableLanguageListSuccess,
);

export const getAvailableLanguageListError = createAction(LanguagesActionTypes.GetAvailableLanguageListError);

export const addSourceLanguage = createAction<IUpdateLanguages>(LanguagesActionTypes.AddSourceLanguage);

export const removeSourceLanguage = createAction<IUpdateLanguages>(LanguagesActionTypes.RemoveSourceLanguage);

export const addTargetLanguage = createAction<IUpdateLanguages>(LanguagesActionTypes.AddTargetLanguage);

export const removeTargetLanguage = createAction<IUpdateLanguages>(LanguagesActionTypes.RemoveTargetLanguage);

export const getPageLanguages = createAction<IGetPageLanguages>(LanguagesActionTypes.GetPageLanguages);

export const getPageLanguagesSuccess = createAction<Array<PageLanguageVariationsDTO>>(
  LanguagesActionTypes.GetPageLanguagesSuccess,
);

export const getPageLanguagesError = createAction(LanguagesActionTypes.GetPageLanguagesError);

export const clearSourceLanguages = createAction<ContentTypeEnum>(LanguagesActionTypes.ClearSourceLanguages);

export const clearTargetLanguages = createAction<ContentTypeEnum>(LanguagesActionTypes.ClearTargetLanguages);
