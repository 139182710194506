import { ContentTypeEnum } from 'enums/contentType';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { removeDisabledCheckbox } from 'store/content/content.actions';
import { api } from 'utils/axiosInstance';
import {
  addSourceLanguage,
  getAvailableLanguageList,
  getAvailableLanguageListError,
  getAvailableLanguageListSuccess,
  getPageLanguages,
  getPageLanguagesError,
  getPageLanguagesSuccess,
} from './languages.actions';

const availableLanguagesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getAvailableLanguageList),
    switchMap(() =>
      from(api.getHubspotLanguages()).pipe(
        switchMap((response) => {
          return of(getAvailableLanguageListSuccess(response));
        }),
        catchError(() => {
          return of(getAvailableLanguageListError());
        }),
      ),
    ),
  );

const getPageLanguagesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getPageLanguages.type),
    mergeMap(({ payload: { pageIds, index } }) =>
      from(api.getLanguageVariations(pageIds)).pipe(
        switchMap(({ data }) => {
          const languages = data.map((language) => language.sourceLanguages).filter((language) => language.length > 0);
          return of(
            getPageLanguagesSuccess(data),
            removeDisabledCheckbox(index),
            addSourceLanguage({
              languages: languages.flat(),
              count: languages.length,
              contentType: ContentTypeEnum.PAGE,
            }),
          );
        }),
        catchError(() => {
          return of(getPageLanguagesError(), removeDisabledCheckbox(index));
        }),
      ),
    ),
  );

export const languagesEpics = combineEpics(availableLanguagesEpic, getPageLanguagesEpic);
