import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import Name from 'components/Name/Name';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { StatusEnum } from 'enums/status';
import moment from 'moment';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { addToSelectedJobs, removeFromSelectedJobs } from 'store/jobs/jobs.actions';
import { getLanguagesSelector } from 'store/languages/languages.selectors';
import { HubspotLanguageDTO, UserConfigurationDTO, XTMJobDTO } from 'utils/restApplicationClient';
import ActionMenu, { IActionMenuProps } from '../ActionMenu/ActionMenu';
import { JobProgress } from './JobRow.styled';
import { statusColorMap } from './statusColorMap';

interface IProps {
  job: XTMJobDTO;
  importConfig: UserConfigurationDTO;
  isSelected: boolean;
  onDialogAction: (jobIds: string[], operation: DialogVariantEnum, isMassAction?: boolean) => () => void;
}

interface IStateProps {
  availableLanguages: HubspotLanguageDTO[];
}

interface IDispatchProps {
  addToSelected: (payload: XTMJobDTO) => AppDispatch;
  removeFromSelected: (payload: XTMJobDTO) => AppDispatch;
}

type PropType = IProps & WithTranslation & IDispatchProps & IStateProps;

class JobRow extends Component<PropType> {
  constructor(props: PropType) {
    super(props);
  }

  renderRowActionIcon(job: XTMJobDTO): JSX.Element | undefined {
    const { onDialogAction } = this.props;
    const isFinished = job.status === StatusEnum.FINISHED;
    const isCancelled = job.status === StatusEnum.CANCELLED;
    const canBeCanceled = !isFinished && !isCancelled;
    const config: IActionMenuProps = { job, onDialogAction };

    if (isFinished) {
      config.operation = DialogVariantEnum.IMPORT;
    } else if (canBeCanceled) {
      config.operation = DialogVariantEnum.CANCEL;
    } else if (isCancelled) {
      config.operation = DialogVariantEnum.DELETE;
    } else {
      config.disabled = true;
    }

    return <ActionMenu {...config}></ActionMenu>;
  }

  getLanguageName(languageCode: string): string {
    const { availableLanguages } = this.props;

    return availableLanguages.find((language) => language.code === languageCode)?.name || '';
  }

  handleSelected(checked: boolean): void {
    const { addToSelected, removeFromSelected, job } = this.props;

    if (checked) {
      addToSelected(job);
    } else {
      removeFromSelected(job);
    }
  }

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    this.setState({ selected: checked });
    this.handleSelected(checked);
  };

  render(): JSX.Element {
    const { job, isSelected } = this.props;

    return (
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={isSelected} onChange={this.onCheckboxChange} value={job.id} />
        </TableCell>
        <TableCell>
          <Name
            width={70}
            text={job.targetLanguage}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={120}
            text={job.contentName}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={120}
            text={job.projectName}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={120}
            text={this.getLanguageName(job.sourceLanguage)}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={120}
            text={this.getLanguageName(job.targetLanguage)}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={100}
            text={moment(job?.createdAt).format('DD-MM-YYYY')}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={100}
            text={job?.dueDate ? moment(job?.dueDate).format('DD-MM-YYYY') : '-'}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell>
          <Name
            width={120}
            text={job.workflowStep ? job.workflowStep : '-'}
            typographyProps={{ color: 'textSecondary', variant: 'body2' }}
            maxLines={1}
          />
        </TableCell>
        <TableCell width={60}>
          <JobProgress isSuccess={job.status === StatusEnum.FINISHED || job.jobProgress === 100}>
            <progress value={job.status === StatusEnum.FINISHED ? 100 : +job.jobProgress} max="100"></progress>
            {` ${job.status === StatusEnum.FINISHED ? 100 : +job.jobProgress}%`}
          </JobProgress>
        </TableCell>
        <TableCell>
          <Name
            width={100}
            text={`queue.status.${job.status}`}
            typographyProps={{ color: 'textSecondary', variant: 'subtitle1' }}
            maxLines={1}
            textColor={statusColorMap[job.status]}
          />
        </TableCell>
        <TableCell>{this.renderRowActionIcon(job)}</TableCell>
      </TableRow>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  addToSelected: (payload: XTMJobDTO): AppDispatch => dispatch(addToSelectedJobs(payload)),
  removeFromSelected: (payload: XTMJobDTO): AppDispatch => dispatch(removeFromSelectedJobs(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  availableLanguages: getLanguagesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobRow));
