import styled from '@emotion/styled';

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px auto;
  width: 100%;

  .MuiAutocomplete-inputRoot {
    padding-top: 3.8px !important;
    padding-bottom: 3.8px !important;
  }
`;

export const InputLabel = styled.div`
  font-size: 12px;
  color: #333;
  opacity: 0.8;
  font-weight: 400;
`;

export const CustomEndAdornment = styled.div`
  position: absolute;
  right: 12px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > p {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
  button {
    flex-grow: 1;
    margin: 0 10px;
  }
  button:first-child {
    margin-left: 0px;
    margin-right: 10px;
  }
  button:last-child {
    margin-right: 0px;
    margin-left: 10px;
  }
  button:only-child {
    margin: 0;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  height: 100%;
  margin-right: 4px;

  .MuiSvgIcon-root {
    font-size: 20px !important;
  }
`;

export const DateButton = styled.div`
  display: flex;
  height: 100%;
  padding-left: 8px;
  border-left: 1px solid rgba(100, 100, 100, 0.45);
`;
