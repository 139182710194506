import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { login, loginError, oAuthRefreshError, oAuthRefreshSuccess } from 'store/auth/auth.actions';
import {
  getContent,
  getContentError,
  getContentSuccess,
  sendForTranslation,
  sendForTranslationError,
  sendForTranslationSuccess,
  setPagination,
} from 'store/content/content.actions';
import {
  getActiveProjects,
  getActiveProjectsError,
  getActiveProjectsSuccess,
  getCustomers,
  getCustomersError,
  getCustomersSuccess,
  getTemplatesByCustomerId,
  getTemplatesByCustomerIdError,
  getTemplatesByCustomerIdSuccess,
} from 'store/settings/settings.actions';
import {
  getSimilarOperationIds,
  getSimilarOperationIdsError,
  getSimilarOperationIdsSuccess,
  performOperation,
  performOperationError,
  performOperationSuccess,
  queryJobs,
  queryJobsError,
  queryJobsSuccess,
  setJobsPagination,
} from '../jobs/jobs.actions';
import { JobsActionTypes } from '../jobs/jobs.actions.types';
import {
  setAuthSpinner,
  setContentSpinner,
  setJobsSpinner,
  setTranslateSpinner,
  setXtmActiveProjectsSpinner,
  setXtmCustomerSpinner,
  setXtmTemplateSpinner,
  toggleJobActionSpinner,
} from './spinner.actions';

const authSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(login.type, loginError.type, oAuthRefreshSuccess.type, oAuthRefreshError.type),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setAuthSpinner(false));
      }
      return of(setAuthSpinner(true));
    }),
  );

const xtmCustomerSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getCustomers.type, getCustomersSuccess.type, getCustomersError.type),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setXtmCustomerSpinner(false));
      }
      return of(setXtmCustomerSpinner(true));
    }),
  );

const xtmTemplateSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getTemplatesByCustomerId.type, getTemplatesByCustomerIdSuccess.type, getTemplatesByCustomerIdError.type),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setXtmTemplateSpinner(false));
      }
      return of(setXtmTemplateSpinner(true));
    }),
  );

const xtmActiveProjectsSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getActiveProjects.type, getActiveProjectsSuccess.type, getActiveProjectsError.type),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setXtmActiveProjectsSpinner(false));
      }
      return of(setXtmActiveProjectsSpinner(true));
    }),
  );

const contentSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getContent.type, getContentSuccess.type, getContentError.type, setPagination.type),
    switchMap(({ payload, type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(
          setContentSpinner({
            contentType: payload.contentType,
            isLoading: false,
          }),
        );
      }
      return of(
        setContentSpinner({
          contentType: payload.contentType,
          isLoading: true,
        }),
      );
    }),
  );

const queryJobsSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(queryJobs.type, queryJobsSuccess.type, queryJobsError.type, setJobsPagination.type),
    debounceTime(100),
    switchMap(({ type }) => {
      if (type === JobsActionTypes.QueryJobs || type === JobsActionTypes.SetJobsPagination) {
        return of(setJobsSpinner(true));
      }
      return of(setJobsSpinner(false));
    }),
  );

const jobActionSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      getSimilarOperationIds.type,
      getSimilarOperationIdsSuccess.type,
      getSimilarOperationIdsError.type,
      performOperation.type,
      performOperationSuccess.type,
      performOperationError.type,
    ),
    debounceTime(100),
    switchMap(({ type }) => {
      if (type === JobsActionTypes.GetSimilarOperationIds || type === JobsActionTypes.PerformOperation) {
        return of(toggleJobActionSpinner(true));
      }
      return of(toggleJobActionSpinner(false));
    }),
  );

const translateSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(sendForTranslation.type, sendForTranslationSuccess.type, sendForTranslationError.type),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setTranslateSpinner(false));
      }
      return of(setTranslateSpinner(true));
    }),
  );

export const spinnerEpics = combineEpics(
  authSpinnerEpic,
  xtmCustomerSpinnerEpic,
  xtmTemplateSpinnerEpic,
  xtmActiveProjectsSpinnerEpic,
  queryJobsSpinnerEpic,
  contentSpinnerEpic,
  jobActionSpinnerEpic,
  translateSpinnerEpic,
);
