import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IContentState } from './content.interface';

const contentStateSelector = ({ content }: AppState): IContentState => content;

export const getDisabledCheckboxesSelector = createSelector(
  contentStateSelector,
  ({ disabledCheckboxes }: IContentState) => disabledCheckboxes,
);

export const getContentSelector = createSelector(
  ({ content }: AppState) => content,
  (content): IContentState => content,
);
