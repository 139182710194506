export const ContentActionTypes = {
  GetContent: 'CONTENT_GET_CONTENT',
  GetContentSuccess: 'CONTENT_GET_CONTENT_SUCCESS',
  GetContentError: 'CONTENT_GET_CONTENT_ERROR',
  SetPagination: 'CONTENT_SET_PAGINATION',
  CheckEntry: 'CONTENT_CHECK_ENTRY',
  CheckAllEntries: 'CONTENT_CHECK_ALL_ENTRIES',
  SendForTranslation: 'CONTENT_SEND_FOR_TRANSLATION',
  SendForTranslationSuccess: 'CONTENT_SEND_FOR_TRANSLATION_SUCCESS',
  SendForTranslationError: 'CONTENT_SEND_FOR_TRANSLATION_ERROR',
  RemoveDisabledCheckbox: 'CONTENT_REMOVE_DISABLED_CHECKBOX',
  ClearCheckEntries: 'CONTENT_CLEAR_CHECK_ENTRIES',
};
