import { createReducer } from '@reduxjs/toolkit';
import { clearStoreError, storeError } from './error.actions';
import { IErrorState } from './error.interface';

const initState: IErrorState = {
  errors: {},
};

export const errorReducer = createReducer(initState, (builder) =>
  builder
    .addCase(storeError, (state, { payload: { key, message } }) => {
      state.errors[key] = message;
    })
    .addCase(clearStoreError, (state, { payload }) => {
      state.errors[payload] = undefined;
    }),
);
