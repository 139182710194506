import { Box, Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Dispatch } from '@reduxjs/toolkit';
import SearchInput from 'components/SearchInput/SearchInput';
import { ContentTypeEnum } from 'enums/contentType';
import React, { ChangeEvent, Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { getContent, setPagination } from 'store/content/content.actions';
import { IContentState, IGetContent } from 'store/content/content.interface';
import { getContentSelector } from 'store/content/content.selectors';
import { ContentService } from 'store/content/content.service';
import { IContentSpinnerState } from 'store/spinner/spinner.interface';
import { getContentSpinnerSelector } from 'store/spinner/spinner.selectors';
import { RefreshButton, TableToolbarDiv } from 'styles/shared.styled';
import { debounce } from 'utils/debounce';
import EntriesTable from '../EntriesTable/EntriesTable';
import { ContentTableTab, ContentTableTableContainer, ContentTableTabsContainer } from './ContentTable.styled';

interface IState {
  activeTab: ContentTypeEnum;
  searchSpinner: boolean;
  searchValue: string;
}

interface IStateProps {
  contentState: IContentState;
  contentSpinner: IContentSpinnerState;
}

interface IDispatchProps {
  getContent: (payload: IGetContent) => AppDispatch;
  setPagination: (payload: IGetContent) => AppDispatch;
}

type PropType = WithTranslation & IDispatchProps & IStateProps;

export class ContentTable extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    const { contentState } = this.props;

    this.state = {
      activeTab: ContentTypeEnum.BLOG,
      searchSpinner: false,
      searchValue: new ContentService().getPagination(ContentTypeEnum.BLOG, contentState).search,
    };
  }

  private debounceFn?: Function;

  handleTabClick = (activeTab: ContentTypeEnum) => (): void => {
    const { contentState } = this.props;

    const searchValue = new ContentService().getPagination(activeTab, contentState).search;

    this.setState({ activeTab, searchValue });
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { setPagination, contentState } = this.props;

    this.setState({
      searchSpinner: true,
      searchValue: event.target.value,
    });
    event.persist();

    if (!this.debounceFn) {
      this.debounceFn = debounce(() => {
        this.setState(
          {
            searchSpinner: false,
          },
          () => {
            const { activeTab, searchValue } = this.state;

            if (!searchValue || searchValue.length >= 3) {
              setPagination({
                contentType: activeTab,
                ...new ContentService().getPagination(activeTab, contentState),
                search: searchValue,
              });
            }
          },
        );
      }, 500);
    }
    this.debounceFn();
  };

  renderTab = (contentType: ContentTypeEnum): JSX.Element => {
    const { activeTab } = this.state;
    const { t } = this.props;

    return (
      <ContentTableTab active={activeTab === contentType} onClick={this.handleTabClick(contentType)}>
        {t(`common.${contentType}`)}
      </ContentTableTab>
    );
  };

  renderTabs = (): JSX.Element => (
    <ContentTableTabsContainer>
      {this.renderTab(ContentTypeEnum.BLOG)}
      {this.renderTab(ContentTypeEnum.MAIL)}
      {this.renderTab(ContentTypeEnum.PAGE)}
      {this.renderTab(ContentTypeEnum.FORM)}
    </ContentTableTabsContainer>
  );

  handleRefresh = (): void => {
    const { getContent, contentState } = this.props;
    const { activeTab } = this.state;

    getContent({
      contentType: activeTab,
      ...new ContentService().getPagination(activeTab, contentState),
    });
  };

  render(): JSX.Element {
    const { t, contentState, contentSpinner } = this.props;
    const { activeTab, searchSpinner, searchValue } = this.state;
    const { blogPostsState, mailsState, pagesState, formsState, disabledCheckboxes } = contentState;

    return (
      <Fragment>
        <TableToolbarDiv>
          <RefreshButton startIcon={<AutorenewIcon />} onClick={this.handleRefresh}>
            <Typography variant="body2" component="div">
              <Box fontWeight="500" color="#555" fontSize="14px">
                {t('addcontent.refresh')}
              </Box>
            </Typography>
          </RefreshButton>
          <SearchInput onChange={this.onSearchChange} spinner={searchSpinner} value={searchValue} />
        </TableToolbarDiv>
        {this.renderTabs()}
        <ContentTableTableContainer hidden={activeTab !== ContentTypeEnum.BLOG}>
          <EntriesTable
            contentData={blogPostsState}
            contentType={ContentTypeEnum.BLOG}
            isLoading={contentSpinner.blogPosts}
          />
        </ContentTableTableContainer>
        <ContentTableTableContainer hidden={activeTab !== ContentTypeEnum.MAIL}>
          <EntriesTable contentData={mailsState} contentType={ContentTypeEnum.MAIL} isLoading={contentSpinner.mails} />
        </ContentTableTableContainer>
        <ContentTableTableContainer hidden={activeTab !== ContentTypeEnum.PAGE}>
          <EntriesTable
            contentData={pagesState}
            contentType={ContentTypeEnum.PAGE}
            isLoading={contentSpinner.pages}
            disabledCheckboxes={disabledCheckboxes}
          />
        </ContentTableTableContainer>
        <ContentTableTableContainer hidden={activeTab !== ContentTypeEnum.FORM}>
          <EntriesTable contentData={formsState} contentType={ContentTypeEnum.FORM} isLoading={contentSpinner.forms} />
        </ContentTableTableContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  contentState: getContentSelector(state),
  contentSpinner: getContentSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getContent: (payload: IGetContent): AppDispatch => dispatch(getContent(payload)),
  setPagination: (payload: IGetContent): AppDispatch => dispatch(setPagination(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContentTable));
