import { RouterUrlEnum } from 'enums/routerUrl';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { RoutePropsType } from '../ExtendedRoute/ExtendedRoute';

export class Helper extends Component<RoutePropsType> {
  render(): JSX.Element {
    const {
      component: PropertyComponent,
      isLoggedIn,
      restricted,
      privateRoute,
      globalConfig,
      detailedConfig,
    } = this.props;

    if (!!privateRoute) {
      return isLoggedIn ? (
        <PropertyComponent {...globalConfig} {...detailedConfig} />
      ) : (
        <Redirect to={RouterUrlEnum.LOGIN} />
      );
    } else {
      return isLoggedIn && restricted ? (
        <Redirect to={RouterUrlEnum.HOME} />
      ) : (
        <PropertyComponent {...globalConfig} {...detailedConfig} />
      );
    }
  }
}
export default Helper;
