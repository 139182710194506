import { ContentTypeEnum } from 'enums/contentType';
import { api } from 'utils/axiosInstance';
import { HubspotProductDTO, Page, RestResponse } from 'utils/restApplicationClient';
import { IContentState, IGetContent, IHubspotSearchContent, IPagination } from './content.interface';

export class ContentService {
  static checkAllEntries<T>(
    entries: Page<T & { checked: boolean }> | undefined,
    value: boolean,
  ): Page<T & { checked: boolean }> | undefined {
    if (entries) {
      return {
        ...entries,
        content: entries.content.map((entry) => ({ ...entry, checked: value })),
      };
    }
    return undefined;
  }

  static getContentFromApi = ({
    contentType,
    page,
    size,
    order,
    orderBy,
    search,
  }: IGetContent): RestResponse<Page<HubspotProductDTO>> => {
    const pagable = {
      page,
      size,
      sort: `${orderBy},${order}`,
      search,
    };

    let content: RestResponse<Page<HubspotProductDTO>>;

    switch (contentType) {
      case ContentTypeEnum.BLOG:
        content = api.findHubspotBlogPosts(pagable);
        break;
      case ContentTypeEnum.MAIL:
        content = api.findHubspotEmails(pagable);
        break;
      case ContentTypeEnum.PAGE:
        content = api.findHubspotPages(pagable);
        break;
      case ContentTypeEnum.FORM:
        content = api.findHubspotForms(pagable);
        break;
    }

    return content;
  };

  static setContent = (response: Page<HubspotProductDTO>): Page<IHubspotSearchContent> => ({
    ...response,
    content: response.content.map((entry) => ({
      ...entry,
      checked: false,
    })),
  });

  getPagination = (contentType: ContentTypeEnum, contentState: IContentState): IPagination => {
    const { blogPostsState, mailsState, pagesState, formsState } = contentState;

    let pagination: IPagination;

    switch (contentType) {
      case ContentTypeEnum.BLOG:
        pagination = blogPostsState.pagination;
        break;
      case ContentTypeEnum.MAIL:
        pagination = mailsState.pagination;
        break;
      case ContentTypeEnum.PAGE:
        pagination = pagesState.pagination;
        break;
      case ContentTypeEnum.FORM:
        pagination = formsState.pagination;
        break;
    }

    return pagination;
  };
}
