import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { loginError } from 'store/auth/auth.actions';
import { apiErrorHandler } from 'utils/handlers/notificationHandler';
import { storeError } from './error.actions';

const displayErrorEpic: Epic = (action$) =>
  action$.pipe(
    ofType(),
    switchMap(({ payload }) => {
      apiErrorHandler(payload);
      return EMPTY;
    }),
  );

const storeErrorEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginError.type),
    switchMap(({ payload }) => of(storeError(payload))),
  );

export const errorEpics = combineEpics(displayErrorEpic, storeErrorEpic);
