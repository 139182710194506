/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Button, CircularProgress, Link, Typography } from '@material-ui/core';
import Header from 'components/Header/Header';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import { Component } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { login } from 'store/auth/auth.actions';
import { clearStoreError } from 'store/error/error.actions';
import { IErrorState } from 'store/error/error.interface';
import { getErrorsSelector } from 'store/error/error.selectors';
import { getAuthSpinnerSelector } from 'store/spinner/spinner.selectors';
import { composeValidators, email, fetchValidatorObject, required } from 'utils/customValidators';
import { getEnvironment } from 'utils/handlers/envHandler';
import { AuthenticationRequest } from 'utils/restApplicationClientTypeOverrides';
import CustomField from '../Input/CustomField';
import {
  CursorPointer,
  LoginFormContainer,
  LoginFormErrorTypography,
  LoginFormLinkDiv,
  LoginFormTopBar,
} from './LoginForm.styled';

interface IFormValues {
  email: string;
  password: string;
  applicationURL: string;
}

interface IState {
  submitValues: IFormValues;
}

interface IDispatchProps {
  login: (payload: AuthenticationRequest) => AppDispatch;
  clearAuthErrors: (payload: string) => AppDispatch;
}

interface IStateProps {
  spinner: boolean;
  authErrors: IErrorState['errors'];
}

type PropType = IDispatchProps & IStateProps & WithTranslation;

export class LoginForm extends Component<PropType, IState> {
  unsubscribe: Unsubscribe;
  constructor(props: PropType) {
    super(props);
    const { clearAuthErrors } = this.props;
    this.state = {
      submitValues: {
        applicationURL: '',
        email: '',
        password: '',
      },
    };

    this.form = createForm({
      onSubmit: this.handleSubmit,
      destroyOnUnregister: true,
    });
    this.unsubscribe = this.form.subscribe(
      () => {
        if (this.props.authErrors['login']) {
          clearAuthErrors('login');
        }
      },
      { values: true },
    );
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }

  form: FormApi<IFormValues, Partial<Record<string, unknown>>>;

  handleSubmit = (formValues: IFormValues): void => {
    this.setState({ submitValues: formValues });
    const { email, password } = formValues;
    this.form.change('email', email.trim());

    this.props.login({ email, password });
  };

  render(): JSX.Element {
    const { t, authErrors, spinner } = this.props;
    const { submitValues } = this.state;

    return (
      <Box>
        <Header />
        <Box display="flex" alignItems="center" marginTop="144px" flexDirection="column">
          <LoginFormContainer>
            <LoginFormTopBar>
              <Typography color="textSecondary" component="div">
                <Box fontWeight="fontWeightBold" color="#748392">
                  {t('loginForm.logIn')}
                </Box>
              </Typography>
            </LoginFormTopBar>
            <Box padding="12px 24px">
              <FinalForm
                onSubmit={this.handleSubmit}
                form={this.form}
                validate={fetchValidatorObject(authErrors['login'], submitValues as unknown as Record<string, string>)}
                subscription={{ submitting: true, pristine: true }}
                render={({ handleSubmit }): JSX.Element => (
                  <form onSubmit={handleSubmit}>
                    {authErrors['login'] && (
                      <LoginFormErrorTypography>{t(authErrors['login'])}</LoginFormErrorTypography>
                    )}
                    <Field
                      name="email"
                      validate={composeValidators([required, email])}
                      format={(value): string | undefined => (value === undefined ? '' : value.trim())}
                    >
                      {({ input, meta }): JSX.Element => (
                        <CustomField label={t('loginForm.labels.username')} inputProps={input} meta={meta} />
                      )}
                    </Field>
                    <Field name="password" validate={composeValidators([required])}>
                      {({ input, meta }): JSX.Element => (
                        <CustomField
                          label={t('loginForm.labels.password')}
                          inputProps={input}
                          meta={meta}
                          textFieldProps={{ type: 'password' }}
                        />
                      )}
                    </Field>
                    <LoginFormLinkDiv>
                      <Link
                        onClick={(): Window | null =>
                          window.open(`${getEnvironment('REACT_APP_APPLICATION_URL')}/reset-password/user`, '_blank')
                        }
                      >
                        <Typography color="secondary" css={CursorPointer} component="div">
                          <Box fontWeight="fontWeightBold">{t('common.resetPassword')}</Box>
                        </Typography>
                      </Link>
                    </LoginFormLinkDiv>
                    <Box marginTop="8px">
                      <Link
                        onClick={(): Window | null =>
                          window.open(`${getEnvironment('REACT_APP_REGISTER_URL')}`, '_blank')
                        }
                      >
                        <Typography color="secondary" variant="subtitle2" css={CursorPointer}>
                          {t('common.signUpXTM')}
                        </Typography>
                      </Link>
                    </Box>
                    <Box marginTop="18px">
                      <Button fullWidth variant="contained" color="secondary" type="submit" disabled={spinner}>
                        {spinner ? <CircularProgress size={24} color="secondary" /> : t('loginForm.logIn')}
                      </Button>
                    </Box>
                  </form>
                )}
              />
            </Box>
          </LoginFormContainer>
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  login: (payload: AuthenticationRequest): AppDispatch => dispatch(login(payload)),
  clearAuthErrors: (payload: string): AppDispatch => dispatch(clearStoreError(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  authErrors: getErrorsSelector(state),
  spinner: getAuthSpinnerSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginForm));
