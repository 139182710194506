/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Component, ComponentType, LazyExoticComponent } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { AppState } from 'store';
import { getIsLoggedInSelector } from 'store/auth/auth.selectors';
import Helper from '../HelperComponent/Helper';

interface IProps {
  restricted?: boolean;
  privateRoute: boolean;
  globalConfig?: any;
  detailedConfig?: any;
  component: ComponentType<any> | ComponentType<RouteComponentProps<any>> | LazyExoticComponent<ComponentType<any>>;
}

interface IStateProps {
  isLoggedIn: boolean;
}

export type RoutePropsType = IProps & RouteProps & IStateProps;

/**
 * @component
 * Component for rendering custom Route component with guard redirecting user to adequately route based on user login state and privateRoute prop.
 *
 * If user is logged public route with restricted prop can't be accessed.
 *
 * If user is not logged private routes can't be accesed.
 *
 * @visibleName
 * Extended Route Component
 *
 * @example
 * const props:RoutePropsType = {...};
 *
 * return (<ExtendedRoute {...props} />);
 *
 * return (
 *  <ExtendedRoute
 *   path={pathname}
 *   exact={!!exact}
 *   privateRoute={privateRoute}
 *   restricted={!!restricted}
 *   component={component}
 *   globalConfig={globalConfig}
 *   detailedConfig={configElement?.config}
 *   key={!!pathname.length ? (pathname as string) : pathname[0]}
 *  />
 * );
 */

class ExtendedRoute extends Component<RoutePropsType> {
  getHelper = (): JSX.Element => {
    return <Helper {...this.props} />;
  };

  render(): JSX.Element {
    const { component: PropertyComponent, isLoggedIn, restricted, privateRoute, ...rest } = this.props;

    return <Route {...rest} render={this.getHelper} />;
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  isLoggedIn: getIsLoggedInSelector(state),
});

export default connect(mapStateToProps)(ExtendedRoute);
