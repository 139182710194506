import { createReducer } from '@reduxjs/toolkit';
import { logoutUserSuccess, oAuthRefreshSuccess, refreshTokenSuccess } from './auth.actions';
import { IAuthState } from './auth.interface';

const initState: IAuthState = {
  isLoggedIn: false,
  isDirty: false,
};

export const authReducer = createReducer(initState, (builder) =>
  builder
    .addCase(oAuthRefreshSuccess, (state, { payload }) => {
      state.isLoggedIn = true;
      state.isDirty = true;
      state.data = payload;
    })
    .addCase(logoutUserSuccess, (state) => {
      state.isLoggedIn = false;
      state.isDirty = true;
    })
    .addCase(refreshTokenSuccess, (state, { payload }) => {
      state.isLoggedIn = true;
      state.isDirty = true;
      state.data = payload;
    }),
);
