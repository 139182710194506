import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1450px !important',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '2px',
      },
      outlined: {
        border: '1px solid rgba(60,60,60,0.7)',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '18px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '10px',
        background: '#fff',
        color: '#333',
      },
      selectMenu: {
        padding: '6px',
        fontSize: '12px',
      },
    },
    MuiListItem: {
      root: {
        color: '#536171',
      },
      gutters: {
        paddingLeft: '22px',
        paddingRight: '22px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: '#fff',
        fontSize: '13px',
        fontWeight: 400,
        color: '#333',
      },
      inputMarginDense: {
        paddingTop: '8.25px',
        paddingBottom: '8.25px',
      },
      adornedEnd: {
        paddingRight: '8px',
      },
      input: {
        '&::placeholder': {
          opacity: '1',
        },
      },
    },
    MuiChip: {
      root: {
        margin: '0 2px !important',
        fontSize: '12px',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormLabel: {
      root: {
        paddingBottom: '10px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '16px',
        textTransform: 'none',
        maxWidth: 'auto',
        color: '#536171',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#fff',
      },
      indicator: {
        backgroundColor: '#00A4E4',
        height: '4px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        backgroundColor: '#2e3645',
        color: '#fff',
        padding: '16px',
      },
    },
    MuiTable: {
      root: {
        marginTop: '-10px',
        borderSpacing: '0 10px !important',
        borderCollapse: 'separate',
      },
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: 600,
        fontSize: '0.9rem',
      },
      h1: {
        fontSize: '1.25rem',
      },
      caption: {
        fontSize: '0.7rem',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#DCE5ED',
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: '#06A6E5 !important',
        fontSize: '24px',
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#DCE5ED',
      },
      stickyHeader: {
        backgroundColor: '#DCE5ED',
      },
      sizeSmall: {
        padding: '8px 4px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
    MuiSvgIcon: {
      root: {
        padding: '2px',
      },
    },
  },
  palette: {
    primary: {
      main: '#536171',
      contrastText: '#536171',
    },
    secondary: {
      main: '#00A4E4',
      contrastText: '#fff',
    },
    text: {
      primary: '#536171',
      secondary: '#536171',
    },
  },
});
