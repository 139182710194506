import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { checkVersion } from 'utils/checkVersion';
import { getEnvironment } from 'utils/handlers/envHandler';
import { initTranslation } from 'utils/initTranslation';
import App from './components/App';
import store from './store';

Sentry.init({
  dsn: getEnvironment('REACT_APP_SENTRY_DSN'),
  environment: process.env.NODE_ENV,
});

async function render(): Promise<void> {
  await checkVersion();

  initTranslation();

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
}

document.addEventListener('DOMContentLoaded', render);
