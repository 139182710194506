import { RouterUrlEnum } from 'enums/routerUrl';
import { RouteTypeEnum } from 'enums/routeType';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IRoute, IDetailedConfig } from 'types/Router';
import { MainRoutes, TrustedRoutes } from 'utils/constants/router.const';
import ExtendedRoute from './ExtendedRoute/ExtendedRoute';

export interface IProps {
  type: RouteTypeEnum;
  globalConfig?: object;
  detailedConfig?: IDetailedConfig[];
}

/**
 * @component
 * Component for rendering Switch component with custom Route components.
 *
 * @visibleName
 * Routes Component
 *
 * @example
 * const routes:IRoute[] = [{...}];
 * const globalConfig:any = {...}; - optional
 * const detailedConfig: IDetailedConfig[] = [{...}]; - optional
 *
 * return (<Routes routes={routes} globalConfig={globalConfig} detailedConfig={detailedConfig} />);
 */
class Routes extends Component<IProps> {
  private getRoutes(routes: IRoute[], globalConfig?: object, detailedConfig?: IDetailedConfig[]): JSX.Element[] {
    return routes.map(({ restricted, privateRoute, pathname, exact, component }: IRoute) => {
      const configElement = detailedConfig?.find((element) => element.pathname === pathname);

      return (
        <ExtendedRoute
          path={pathname}
          exact={!!exact}
          privateRoute={privateRoute}
          restricted={!!restricted}
          component={component}
          globalConfig={globalConfig}
          detailedConfig={configElement?.config}
          key={!!pathname.length ? (pathname as string) : pathname[0]}
        />
      );
    });
  }

  render(): JSX.Element {
    const { globalConfig, detailedConfig, type } = this.props;

    return (
      <Switch>
        {this.getRoutes(type === RouteTypeEnum.MAIN ? MainRoutes : TrustedRoutes, globalConfig, detailedConfig)}
        <Route path={RouterUrlEnum.WILDCARD}>
          <Redirect to={RouterUrlEnum.CONTENT} />
        </Route>
      </Switch>
    );
  }
}

export default Routes;
