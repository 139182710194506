import { createAction } from '@reduxjs/toolkit';
import { SpinnerActionTypes } from './spinner.actions.types';
import { ISetContentSpinner } from './spinner.interface';

export const setAuthSpinner = createAction<boolean>(SpinnerActionTypes.setAuthSpinner);

export const setXtmCustomerSpinner = createAction<boolean>(SpinnerActionTypes.setXtmCustomerSpinner);

export const setXtmTemplateSpinner = createAction<boolean>(SpinnerActionTypes.setXtmTemplateSpinner);

export const setXtmLanguagesSpinner = createAction<boolean>(SpinnerActionTypes.setXtmLanguagesSpinner);

export const setXtmActiveProjectsSpinner = createAction<boolean>(SpinnerActionTypes.setXtmActiveProjectsSpinner);

export const setJobsSpinner = createAction<boolean>(SpinnerActionTypes.setJobsSpinner);

export const toggleJobActionSpinner = createAction<boolean>(SpinnerActionTypes.toggleJobActionSpinner);

export const setContentSpinner = createAction<ISetContentSpinner>(SpinnerActionTypes.setContentSpinner);

export const setTranslateSpinner = createAction<boolean>(SpinnerActionTypes.setTranslateSpinner);

export const setPreviewSpinner = createAction<boolean>(SpinnerActionTypes.setPreviewSpinner);
