import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { ISettingsState } from './settings.interface';

export const settingsStateSelector = ({ settings }: AppState): ISettingsState => settings;

export const getXtmCustomerSelector = createSelector(settingsStateSelector, ({ xtmCustomer }) => xtmCustomer);

export const getXtmTemplateSelector = createSelector(settingsStateSelector, ({ xtmTemplate }) => xtmTemplate);

export const getDefaultSourceLanguage = createSelector(
  settingsStateSelector,
  ({ defaultSourceLanguage }) => defaultSourceLanguage,
);

export const getForceDefaultCustomerSettings = createSelector(
  settingsStateSelector,
  ({ forceDefaultCustomerSettings }) => forceDefaultCustomerSettings,
);

export const getActiveProjectsSelector = createSelector(settingsStateSelector, ({ activeProjects }) => activeProjects);
