/**  @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Box, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { AxiosError } from 'axios';
import { Fragment } from 'react';
import { Slide, toast } from 'react-toastify';
import i18n from '../initTranslation';
import { clearToken } from './tokenHandler';

interface ApiError {
  args: Array<Record<string, unknown>>;
  errorMessage: string;
  errorCode: string;
}

const toastBarStyles: { [key: string]: SerializedStyles } = {
  success: css`
    background-color: ${green[500]};
  `,
  error: css`
    background-color: ${red[500]};
  `,
};

const getIcon = (type: 'success' | 'error'): JSX.Element | undefined => {
  switch (type) {
    case 'success': {
      return (
        <Box marginRight="8px" color={green[500]}>
          <CheckCircleIcon />
        </Box>
      );
    }
    case 'error': {
      return (
        <Box marginRight="8px" color={red[500]}>
          <ErrorIcon />
        </Box>
      );
    }
    default: {
      return undefined;
    }
  }
};

export const notify = (messageKey: string, type: 'success' | 'error'): void => {
  toast(
    <Fragment>
      <Box width="8px" height="auto" marginRight="8px" css={toastBarStyles[type]} />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        padding="8px"
        paddingLeft="0"
        alignSelf="center"
      >
        {getIcon(type)}
        <Typography color="textSecondary" component="div">
          <Box maxWidth="240px">{i18n.t(messageKey)}</Box>
        </Typography>
      </Box>
    </Fragment>,
    {
      position: 'top-center',
      hideProgressBar: true,
      draggable: false,
      transition: Slide,
    },
  );
};

export const apiErrorHandler = (reason: AxiosError<ApiError>): AxiosError<ApiError> => {
  if (reason.isAxiosError) {
    if (reason.response?.status === 403) {
      notify('errormessage.unauthorized', 'error');
      clearToken();
    } else if (Array.isArray(reason.response?.data)) {
      // eslint-disable-next-line no-unused-expressions
      reason.response?.data.forEach((error) => {
        notify(error.errorCode || 'errormessage.somethingWrong', 'error');
        if (error.errorCode === 'errormessage.unauthorized') {
          clearToken();
        }
      });
    } else {
      notify(reason.response?.data.errorCode || 'errormessage.somethingWrong', 'error');
    }
  } else {
    notify(i18n.t('errormessage.somethingWrong'), 'error');
  }

  return reason;
};
