/* eslint-disable promise/prefer-await-to-callbacks */
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import store from 'store';
import { sendForTranslationSuccess } from 'store/content/content.actions';
import { api } from 'utils/axiosInstance';
import { apiErrorHandler } from 'utils/handlers/notificationHandler';
import {
  clearActiveProjects,
  clearSettings,
  getActiveProjects,
  getActiveProjectsError,
  getActiveProjectsSuccess,
  getCustomers,
  getCustomersError,
  getCustomersSuccess,
  getTemplatesByCustomerId,
  getTemplatesByCustomerIdError,
  getTemplatesByCustomerIdSuccess,
  importUserSettings,
} from './settings.actions';

export const getCustomersEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getCustomers.type),
    switchMap(() =>
      from(api.getXTMCustomers$GET$api_content_customers()).pipe(
        switchMap(({ data: xtmCustomer }) => {
          store.dispatch(getCustomersSuccess(xtmCustomer));

          if (xtmCustomer.defaultCustomerId) {
            store.dispatch(importUserSettings());

            return of(getTemplatesByCustomerId(xtmCustomer.defaultCustomerId));
          }
          return EMPTY;
        }),
        catchError((error) => {
          apiErrorHandler(error);
          return of(getCustomersError());
        }),
      ),
    ),
  );

export const getTemplatesByCustomerIdEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getTemplatesByCustomerId.type),
    switchMap(({ payload: customerId }) =>
      from(api.getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(customerId)).pipe(
        switchMap(({ data: xtmTemplate }) => {
          store.dispatch(getTemplatesByCustomerIdSuccess(xtmTemplate));

          if (
            xtmTemplate.defaultTemplateId &&
            xtmTemplate.templates.find((template) => template.xtmTemplateId === xtmTemplate.defaultTemplateId)
          ) {
            // @TODO
            return EMPTY;
          }

          return EMPTY;
        }),
        catchError((error) => {
          apiErrorHandler(error);
          return of(getTemplatesByCustomerIdError());
        }),
      ),
    ),
  );

export const getActiveProjectsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getActiveProjects.type, sendForTranslationSuccess.type),
    switchMap(({ payload }) =>
      from(api.getActiveXTMProjects(payload)).pipe(
        switchMap(({ data: activeProjects }) => of(getActiveProjectsSuccess(activeProjects))),
        catchError((error) => {
          apiErrorHandler(error);
          return of(getActiveProjectsError());
        }),
      ),
    ),
  );

export const clearSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(clearSettings.type),
    take(1),
    switchMap(() => of(clearSettings())),
  );

export const clearActiveProjectsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(clearActiveProjects.type),
    take(1),
    switchMap(() => of(clearActiveProjects())),
  );

export const settingsEpics = combineEpics(
  getCustomersEpic,
  getTemplatesByCustomerIdEpic,
  getActiveProjectsEpic,
  clearSettingsEpic,
  clearActiveProjectsEpic,
);
