import {
  Box,
  Checkbox,
  CircularProgress,
  Modal,
  Table,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import CustomPagination from 'components/CustomPagination/CustomPagination';
import CustomDialog from 'components/Dialog/CustomDialog';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { OrderEnum } from 'enums/orderEnum';
import { StatusEnum } from 'enums/status';
import React, { ChangeEvent, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IJobsPagination } from 'store/jobs/jobs.interface';
import { RefreshButton, TableToolbarDiv } from 'styles/shared.styled';
import { debounce } from 'utils/debounce';
import { JobOperation, MassOperationRequest, UserConfigurationDTO, XTMJobDTO } from 'utils/restApplicationClient';
import { AppDispatch, AppState } from '../../store';
import {
  addAllToSelectedJobs,
  clearSelectedJobs,
  performOperation,
  setJobsPagination,
  setOpenedDialogs,
} from '../../store/jobs/jobs.actions';
import {
  getJobsPaginationSelector,
  getJobsSelector,
  getOpenedDialogsSelector,
  getSelectedJobsSelector,
  getSimilarIdsSelector,
} from '../../store/jobs/jobs.selectors';
import { getJobsSpinnerSelector } from '../../store/spinner/spinner.selectors';
import SearchInput from '../SearchInput/SearchInput';
import ActionMenu, { IActionMenuProps } from './ActionMenu/ActionMenu';
import JobRow from './JobRow/JobRow';
import {
  TranslationQueueContentContainer,
  TranslationQueueHeaderMenuContainer,
  TranslationQueueModalContent,
  TranslationQueueSpinnerContainer,
  TranslationQueueTableBody,
  TranslationQueueTableContainer,
  TranslationQueueTableHead,
  TranslationQueueTableHeaderCell,
} from './TranslationQueue.styled';

interface IProps {
  importConfig: UserConfigurationDTO;
}

interface IState {
  searchSpinner: boolean;
  isMassAction: boolean;
  dialogVariant: DialogVariantEnum;
  actionVariant: DialogVariantEnum;
  searchValue: string;
}

interface IStateProps {
  queryJobsSpinner: boolean;
  jobs: XTMJobDTO[];
  pagination: IJobsPagination;
  selectedJobs: XTMJobDTO[];
  openedDialogs: DialogVariantEnum[];
  similarIds: string[];
}

interface IDispatchProps {
  setJobsPagination: (payload: IJobsPagination) => AppDispatch;
  performOperation: (payload: MassOperationRequest) => AppDispatch;
  addAllToSelectedJobs: () => AppDispatch;
  clearSelectedJobs: () => AppDispatch;
  setOpenedDialogs: (payload: DialogVariantEnum[]) => AppDispatch;
}

type PropType = IProps & IStateProps & IDispatchProps & WithTranslation;

export class TranslationQueue extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    const { pagination } = this.props;

    this.state = {
      searchSpinner: false,
      isMassAction: false,
      dialogVariant: DialogVariantEnum.IMPORT,
      actionVariant: DialogVariantEnum.IMPORT,
      searchValue: pagination.search || '',
    };
  }

  private debounceFn?: Function;
  jobIds = [''];

  componentDidMount(): void {
    this.props.setJobsPagination(this.props.pagination);
  }

  onDialogAction =
    (jobIds: string[], operation: DialogVariantEnum, isMassAction = false) =>
    (): void => {
      const { setOpenedDialogs, openedDialogs } = this.props;

      this.jobIds = jobIds;
      this.setState({
        dialogVariant: operation,
        actionVariant: operation,
        isMassAction,
      });
      setOpenedDialogs([...openedDialogs, operation]);
    };

  onRefresh = (): void => {
    this.props.setJobsPagination({
      ...this.props.pagination,
      forceRefresh: true,
    });
  };

  onChangePage = (page: number): void => {
    this.props.setJobsPagination({ ...this.props.pagination, page });
  };

  onChangeRowsPerPage = (size: number): void => {
    this.props.setJobsPagination({
      ...this.props.pagination,
      size,
      page: 0,
    });
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      searchSpinner: true,
      searchValue: event.target.value,
    });
    event.persist();

    if (!this.debounceFn) {
      this.debounceFn = debounce(() => {
        this.setState(
          {
            searchSpinner: false,
          },
          () => {
            const { searchValue } = this.state;

            if (!searchValue || searchValue.length >= 3) {
              this.props.setJobsPagination({
                ...this.props.pagination,
                search: searchValue,
              });
            }
          },
        );
      }, 500);
    }
    this.debounceFn();
  };

  onChangeOrder = (property: string): (() => void) => {
    return (): void => {
      const {
        pagination: { orderBy, order },
      } = this.props;
      const isAsc = orderBy === property && order === OrderEnum.ASC;
      const paginationOrder = isAsc ? OrderEnum.DESC : OrderEnum.ASC;

      this.props.setJobsPagination({
        ...this.props.pagination,
        order: paginationOrder,
        orderBy: property,
        sort: `${property},${paginationOrder}`,
      });
    };
  };

  onCheckboxChange = (): void => {
    const { jobs, selectedJobs, addAllToSelectedJobs, clearSelectedJobs } = this.props;

    if (selectedJobs.length !== jobs.length) {
      addAllToSelectedJobs();
    } else {
      clearSelectedJobs();
    }
  };

  renderRows(): JSX.Element | Array<JSX.Element> {
    const { t, importConfig, jobs, queryJobsSpinner, selectedJobs } = this.props;

    if (queryJobsSpinner) {
      return (
        <TableRow>
          <TableCell colSpan={12}>
            <TranslationQueueSpinnerContainer>
              <CircularProgress size={40} color="secondary" />
            </TranslationQueueSpinnerContainer>
          </TableCell>
        </TableRow>
      );
    }

    if (!jobs || (jobs && jobs.length === 0)) {
      return (
        <TableRow>
          <TableCell colSpan={12}>
            <TranslationQueueSpinnerContainer>
              <SpeakerNotesOffIcon fontSize="large" color="disabled" />
              <Typography color="textSecondary" variant="caption">
                {t('queue.tableEmpty')}
              </Typography>
            </TranslationQueueSpinnerContainer>
          </TableCell>
        </TableRow>
      );
    }

    return jobs.map((job) => (
      <JobRow
        key={job.id}
        job={job}
        importConfig={importConfig}
        onDialogAction={this.onDialogAction}
        isSelected={!!selectedJobs.filter((element) => element.id === job.id).length}
      />
    ));
  }

  renderHeaders(): Array<JSX.Element> {
    const { t, selectedJobs, jobs } = this.props;
    const {
      pagination: { order, orderBy },
    } = this.props;
    const headers: Array<{
      label: string;
      isProject?: boolean;
      hiddenName?: string;
      width?: number;
      component?: JSX.Element;
    }> = [
      {
        label: 'hubspotTargetLanguage.code',
        width: 70,
      },
      { label: 'hubspotProduct.contentName', width: 120 },
      { label: 'projectName', isProject: true, width: 120 },
      { label: 'hubspotSourceLanguage.name', width: 120 },
      { label: 'hubspotTargetLanguage.name', width: 120 },
      { label: 'createdAt', isProject: true, width: 120 },
      { label: 'dueDate', isProject: true, width: 120 },
      { label: 'workflowStep', width: 150 },
      { label: 'jobProgress', width: 70 },
      { label: 'status', width: 120 },
      { label: '', width: 35, component: <MoreVertIcon /> },
    ];
    const headerRow = headers.map(({ label, width, isProject, component, hiddenName }): JSX.Element => {
      const propertyName = `${isProject ? 'project.' : ''}${!!hiddenName ? hiddenName : label}`;

      return (
        <TranslationQueueTableHeaderCell
          width={width}
          align="left"
          sortDirection={orderBy === propertyName ? order : false}
          key={label}
        >
          {component || (
            <TableSortLabel
              active={orderBy === propertyName}
              onClick={this.onChangeOrder(propertyName)}
              direction={orderBy === propertyName ? order : OrderEnum.ASC}
              IconComponent={ArrowDropDown}
            >
              {t(`queue.headers.${label}`)}
            </TableSortLabel>
          )}
        </TranslationQueueTableHeaderCell>
      );
    });

    headerRow.unshift(
      <TranslationQueueTableHeaderCell padding="checkbox" key={'header-checkbox'}>
        <Checkbox
          checked={selectedJobs.length === jobs.length}
          indeterminate={!!selectedJobs.length && selectedJobs.length !== jobs.length}
          onChange={this.onCheckboxChange}
        />
      </TranslationQueueTableHeaderCell>,
    );

    return headerRow;
  }

  handleDialogClose = (action: ButtonVariantEnum, variant?: DialogVariantEnum): void => {
    const { similarIds, performOperation, setOpenedDialogs, openedDialogs } = this.props;
    const { actionVariant } = this.state;
    let operation: JobOperation = 'DELETE';

    if (action === ButtonVariantEnum.QUIT && variant !== DialogVariantEnum.SIMILAR_OPERATION) {
      this.setState({
        isMassAction: false,
      });
      setOpenedDialogs([]);
      return;
    }

    if (!!similarIds.length && variant === DialogVariantEnum.SIMILAR_OPERATION && action === ButtonVariantEnum.SEND) {
      this.jobIds = [...this.jobIds, ...similarIds];
    }

    switch (actionVariant) {
      case DialogVariantEnum.IMPORT: {
        operation = 'IMPORT';
        break;
      }
      case DialogVariantEnum.DELETE: {
        operation = 'DELETE';
        break;
      }
      case DialogVariantEnum.CANCEL: {
        operation = 'CANCEL';
        break;
      }
    }

    //@TODO check after BE changes to find EP
    if (variant === DialogVariantEnum.SIMILAR_OPERATION || !similarIds.length) {
      setTimeout(() => {
        performOperation({ jobsIds: this.jobIds, operation });
      }, 0);
    } else {
      this.setState({
        dialogVariant: DialogVariantEnum.SIMILAR_OPERATION,
      });
      setOpenedDialogs([...openedDialogs, DialogVariantEnum.SIMILAR_OPERATION]);
    }
  };

  handleHeaderActionMenu(): JSX.Element {
    const { selectedJobs } = this.props;
    const isFinished = !!selectedJobs.length ? selectedJobs.every((job) => job.status === StatusEnum.FINISHED) : false;
    const isCancelled = !!selectedJobs.length
      ? selectedJobs.every((job) => job.status === StatusEnum.CANCELLED)
      : false;
    const isToBeCanceled = !!selectedJobs.length
      ? selectedJobs.every(
          (job) =>
            job.status === StatusEnum.NEW || job.status === StatusEnum.IN_PROGRESS || job.status === StatusEnum.SENT,
        )
      : false;

    const canBeCanceled = !isFinished && !isCancelled && isToBeCanceled;
    const isDisabled = !selectedJobs.length || (!isFinished && !isCancelled && !isToBeCanceled);
    const config: IActionMenuProps = {
      selectedJobs,
      headerMenu: true,
      onDialogAction: this.onDialogAction,
    };

    if (isFinished) {
      config.operation = DialogVariantEnum.IMPORT;
    }

    if (canBeCanceled) {
      config.operation = DialogVariantEnum.CANCEL;
    }

    if (isCancelled) {
      config.operation = DialogVariantEnum.DELETE;
    }

    if (isDisabled) {
      config.disabled = true;
    }

    return <ActionMenu {...config}></ActionMenu>;
  }

  render(): JSX.Element {
    const { searchSpinner, dialogVariant, searchValue } = this.state;
    const {
      t,
      pagination: { totalPages, page, size },
      openedDialogs,
    } = this.props;

    return (
      <TranslationQueueContentContainer>
        <Modal
          open={!!openedDialogs.length}
          disableBackdropClick
          disableAutoFocus
          disableEnforceFocus
          BackdropProps={{
            style: { backgroundColor: 'rgba(255,255,255,0.5)' },
          }}
        >
          <TranslationQueueModalContent>
            <CircularProgress color="secondary" size={60} />
          </TranslationQueueModalContent>
        </Modal>
        <CustomDialog
          onClose={this.handleDialogClose}
          open={!!openedDialogs.length}
          variant={dialogVariant}
          jobIds={this.jobIds}
        />
        <TableToolbarDiv>
          <TranslationQueueHeaderMenuContainer>
            {this.handleHeaderActionMenu()}
            <RefreshButton onClick={this.onRefresh} data-testid="refreshButton" startIcon={<AutorenewIcon />}>
              <Box fontWeight="500" color="#555" fontSize="14px">
                {t('queue.refresh')}
              </Box>
            </RefreshButton>
          </TranslationQueueHeaderMenuContainer>

          <SearchInput onChange={this.onSearchChange} spinner={searchSpinner} value={searchValue} />
        </TableToolbarDiv>
        <TranslationQueueTableContainer>
          <Table size="small" style={{ width: '100%' }} stickyHeader>
            <TranslationQueueTableHead>
              <TableRow>{this.renderHeaders()}</TableRow>
            </TranslationQueueTableHead>
            <TranslationQueueTableBody>{this.renderRows()}</TranslationQueueTableBody>
          </Table>
        </TranslationQueueTableContainer>
        <CustomPagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          defaultSize={size}
          page={page}
          totalPages={totalPages}
          onPageChange={this.onChangePage}
          onRowChange={this.onChangeRowsPerPage}
        ></CustomPagination>
      </TranslationQueueContentContainer>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  jobs: getJobsSelector(state),
  pagination: getJobsPaginationSelector(state),
  queryJobsSpinner: getJobsSpinnerSelector(state),
  selectedJobs: getSelectedJobsSelector(state),
  similarIds: getSimilarIdsSelector(state),
  openedDialogs: getOpenedDialogsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setJobsPagination: (payload: IJobsPagination): AppDispatch => dispatch(setJobsPagination(payload)),
  performOperation: (payload: MassOperationRequest): AppDispatch => dispatch(performOperation(payload)),
  addAllToSelectedJobs: (): AppDispatch => dispatch(addAllToSelectedJobs()),
  clearSelectedJobs: (): AppDispatch => dispatch(clearSelectedJobs()),
  setOpenedDialogs: (payload: DialogVariantEnum[]): AppDispatch => dispatch(setOpenedDialogs(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TranslationQueue));
