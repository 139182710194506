import { StorageKeyEnum } from '../../enums/StorageKeyEnum';
import { getItem, removeItems } from './storageHandler';

const { tokenStorageKey, refreshTokenStorageKey, tokenTtlStorageKey } = StorageKeyEnum;

/**
 * Clear all application related data from local storage
 */
export const clearToken = (): void => {
  removeItems([tokenStorageKey, refreshTokenStorageKey, tokenTtlStorageKey]);
};

/**
 * Return authentication token TTL value from local storage
 */
export const getTTL = (): number => {
  return Number(getItem(tokenTtlStorageKey));
};

/**
 * Return authentication token from local storage
 */
export const getAuthToken = (): string | null => {
  return getItem(tokenStorageKey);
};
