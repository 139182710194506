import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';

export const SearchInputTextField = styled(TextField)`
  margin-left: 12px;
  width: 100% !important;
  max-width: 300px !important;

  > div input {
    font-size: 14px;
    padding: 8px !important;
  }

  .Mui-focused {
    .MuiSvgIcon-colorDisabled {
      color: #536171;
    }
  }
`;
