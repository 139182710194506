import styled from '@emotion/styled';
import { Tab } from '@material-ui/core';

export const CustomNavbarContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  box-shadow: none !important;
  background-color: #fff;

  button {
    > span {
      text-transform: none !important;

      > svg {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }

  .MuiTab-textColorSecondary {
    color: #2e3844;
  }
`;

export const CustomNavbarTab = styled(Tab)`
  flex-grow: 1;
  min-width: unset !important;
  padding: 6px 30px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
`;
