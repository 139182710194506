import { createAction } from '@reduxjs/toolkit';
import { GenericAxiosResponse } from 'axios';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { Page, XTMJobDTO, MassOperationRequest } from '../../utils/restApplicationClient';
import { QueryJobsRequestParameters } from '../../utils/restApplicationClientTypeOverrides';
import { JobsActionTypes } from './jobs.actions.types';
import { IJobsPagination } from './jobs.interface';

export const queryJobs = createAction<QueryJobsRequestParameters>(JobsActionTypes.QueryJobs);

export const queryJobsError = createAction(JobsActionTypes.QueryJobsError);

export const queryJobsSuccess = createAction<Page<XTMJobDTO>>(JobsActionTypes.QueryJobsSuccess);

export const performOperation = createAction<MassOperationRequest>(JobsActionTypes.PerformOperation);

export const performOperationError = createAction(JobsActionTypes.PerformOperationError);

export const performOperationSuccess = createAction(JobsActionTypes.PerformOperationSuccess);

export const addToSelectedJobs = createAction<XTMJobDTO>(JobsActionTypes.AddToSelectedJobs);

export const addAllToSelectedJobs = createAction(JobsActionTypes.AddAllToSelectedJobs);

export const removeFromSelectedJobs = createAction<XTMJobDTO>(JobsActionTypes.RemoveFromSelectedJobs);

export const clearSelectedJobs = createAction(JobsActionTypes.ClearSelectedJobs);

export const getSimilarOperationIds = createAction<MassOperationRequest>(JobsActionTypes.GetSimilarOperationIds);

export const getSimilarOperationIdsSuccess = createAction<GenericAxiosResponse<string[]>>(
  JobsActionTypes.GetSimilarOperationIdsSuccess,
);

export const getSimilarOperationIdsError = createAction(JobsActionTypes.GetSimilarOperationIdsError);

export const setJobsPagination = createAction<IJobsPagination>(JobsActionTypes.SetJobsPagination);

export const setOpenedDialogs = createAction<DialogVariantEnum[]>(JobsActionTypes.SetOpenedDialogs);
