import { createAction } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import { ActiveProjectSearchDTO } from 'utils/restApplicationClient';
import { TranslateRequestDTO } from 'utils/restApplicationClientTypeOverrides';
import { ContentActionTypes } from './content.actions.types';
import { ICheckAllEntries, ICheckEntry, IGetContent, IGetContentSuccess } from './content.interface';

export const getContent = createAction<IGetContent>(ContentActionTypes.GetContent);

export const getContentSuccess = createAction<IGetContentSuccess>(ContentActionTypes.GetContentSuccess);

export const getContentError = createAction<ContentTypeEnum>(ContentActionTypes.GetContentError);

export const setPagination = createAction<IGetContent>(ContentActionTypes.SetPagination);

export const checkEntry = createAction<ICheckEntry>(ContentActionTypes.CheckEntry);

export const clearCheckEntries = createAction(ContentActionTypes.ClearCheckEntries);

export const checkAllEntries = createAction<ICheckAllEntries>(ContentActionTypes.CheckAllEntries);

export const sendForTranslation = createAction<TranslateRequestDTO>(ContentActionTypes.SendForTranslation);

export const sendForTranslationSuccess = createAction<ActiveProjectSearchDTO>(
  ContentActionTypes.SendForTranslationSuccess,
);
export const sendForTranslationError = createAction(ContentActionTypes.SendForTranslationError);

export const removeDisabledCheckbox = createAction<number>(ContentActionTypes.RemoveDisabledCheckbox);
