/**  @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Divider,
  Typography,
} from '@material-ui/core';
import { CustomDialogButtonContainer, CustomDialogContent } from 'components/Dialog/CustomDialog.styled';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { AppDispatch, AppState } from 'store';
import { getSimilarOperationIds } from 'store/jobs/jobs.actions';
import { JobsActionTypes } from 'store/jobs/jobs.actions.types';
import { getSimilarIdsSelector } from 'store/jobs/jobs.selectors';
import { getJobActionSpinnerSelector } from 'store/spinner/spinner.selectors';
import { JobOperation, MassOperationRequest } from 'utils/restApplicationClient';

export interface IJobActionProps {
  variant?: DialogVariantEnum;
  onClose: (response: ButtonVariantEnum, variant?: DialogVariantEnum) => void;
  jobIds: string[];
}

interface IDispatchProps {
  getSimilarOperationIds: (payload: MassOperationRequest) => AppDispatch;
}

interface IStateProps {
  jobActionSpinner: boolean;
  similarIds: string[];
}

type PropType = IJobActionProps & WithTranslation & IStateProps & IDispatchProps;

class JobAction extends Component<PropType> {
  private unsubscribe: () => void = () => ({});
  private getVariantText = (variant: IJobActionProps['variant']): string | undefined => {
    const { jobIds } = this.props;
    const multiple = jobIds?.length > 1;

    switch (variant) {
      case DialogVariantEnum.IMPORT: {
        return `modal.inTranslationQueueImportAction${multiple ? 's' : ''}`;
      }
      case DialogVariantEnum.DELETE: {
        return `modal.inTranslationQueueRemoveAction${multiple ? 's' : ''}`;
      }
      case DialogVariantEnum.CANCEL: {
        return `modal.inTranslationQueueCancelAction${multiple ? 's' : ''}`;
      }
      case DialogVariantEnum.SIMILAR_OPERATION: {
        return `modal.similarOperation`;
      }
      default:
        return undefined;
    }
  };

  private handleClose = (reason: ButtonVariantEnum) => (): void => {
    const { variant, getSimilarOperationIds, jobIds, onClose } = this.props;

    if (variant === DialogVariantEnum.SIMILAR_OPERATION || reason === ButtonVariantEnum.QUIT) {
      onClose(reason, this.props.variant);
    } else {
      //jobOperation must have the same values as DialogVariantEnum - if BE provides changes to one, check another one.
      getSimilarOperationIds({
        jobsIds: jobIds,
        operation: this.props.variant as JobOperation,
      });
      this.unsubscribe = subscribeActionAfter(JobsActionTypes.GetSimilarOperationIdsSuccess, () =>
        onClose(reason, this.props.variant),
      );
    }
  };

  render(): JSX.Element {
    const { t, variant, similarIds, jobActionSpinner } = this.props;

    return (
      <Fragment>
        <CustomDialogContent>
          <DialogContentText>
            <Typography component="span" color="textSecondary">
              {t(this.getVariantText(variant) || '', {
                count: similarIds.length,
              })}
            </Typography>
          </DialogContentText>
        </CustomDialogContent>
        <Divider />
        <DialogActions>
          <CustomDialogButtonContainer>
            <Button variant="outlined" onClick={this.handleClose(ButtonVariantEnum.QUIT)} disabled={jobActionSpinner}>
              {jobActionSpinner ? (
                <CircularProgress size="24px" color="secondary" />
              ) : variant === DialogVariantEnum.SIMILAR_OPERATION ? (
                <Box fontSize="13px" fontWeight="500" color="#333">
                  {t('modal.no')}
                </Box>
              ) : (
                <Box fontSize="13px" fontWeight="500" color="#333">
                  {t('modal.quit')}
                </Box>
              )}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleClose(ButtonVariantEnum.SEND)}
              disabled={jobActionSpinner}
            >
              {jobActionSpinner ? (
                <CircularProgress size="24px" color="secondary" />
              ) : variant === DialogVariantEnum.SIMILAR_OPERATION ? (
                <Box fontSize="13px" fontWeight="500">
                  {t('modal.yes')}
                </Box>
              ) : (
                <Box fontSize="13px" fontWeight="500">
                  {t('modal.ok')}
                </Box>
              )}
            </Button>
          </CustomDialogButtonContainer>
        </DialogActions>
      </Fragment>
    );
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getSimilarOperationIds: (payload: MassOperationRequest): AppDispatch => dispatch(getSimilarOperationIds(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  jobActionSpinner: getJobActionSpinnerSelector(state),
  similarIds: getSimilarIdsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobAction));
