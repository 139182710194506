import { createReducer } from '@reduxjs/toolkit';
import { IPreviewState } from './preview.interface';
import { togglePreview } from './preview.actions';

const initState: IPreviewState = {
  showPreview: false,
};

export const previewReducer = createReducer(initState, (builder) =>
  builder.addCase(togglePreview, (state, action) => {
    state.showPreview = action.payload.showPreview;
    state.previewUrl = action.payload?.previewUrl || state?.previewUrl;
  }),
);
