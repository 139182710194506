import { Global } from '@emotion/core';
import { ThemeProvider } from '@material-ui/core';
import { RouteTypeEnum } from 'enums/routeType';
import React from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { oAuthConnect, refreshToken } from 'store/auth/auth.actions';
import { getIsDirtySelector, getIsLoggedInSelector } from 'store/auth/auth.selectors';
import { getAvailableLanguageList } from 'store/languages/languages.actions';
import history from 'utils/constants/history.const';
import { mainStyles } from '../main.style';
import { theme } from '../theme';
import Routes from './Routes/Routes';

interface IStateProps {
  refreshed: boolean;
  isLogged: boolean;
}

interface IDispatchProps {
  refreshToken: () => AppDispatch;
  getAvailableLanguages: () => AppDispatch;
  connectHubspot: (payload: string) => AppDispatch;
}

type PropType = IDispatchProps & IStateProps;

class App extends React.Component<PropType> {
  constructor(props: PropType) {
    super(props);
    const codeQueryParameter = new URLSearchParams(window.location.search).get('code');
    if (codeQueryParameter) {
      this.props.connectHubspot(codeQueryParameter);
    } else {
      this.props.refreshToken();
    }
  }

  componentDidUpdate(): void {
    if (this.props.isLogged) {
      this.props.getAvailableLanguages();
    }
  }

  render(): JSX.Element {
    const { refreshed } = this.props;

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <ToastContainer position="top-center" autoClose={5000} hideProgressBar closeOnClick pauseOnHover />
          <Global styles={mainStyles} />
          {refreshed && <Routes type={RouteTypeEnum.MAIN} />}
        </ThemeProvider>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  refreshToken: (): AppDispatch => dispatch(refreshToken()),
  getAvailableLanguages: (): AppDispatch => dispatch(getAvailableLanguageList()),
  connectHubspot: (payload: string): AppDispatch => dispatch(oAuthConnect(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  refreshed: getIsDirtySelector(state),
  isLogged: getIsLoggedInSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
