import { createReducer } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import {
  setAuthSpinner,
  setContentSpinner,
  setJobsSpinner,
  setPreviewSpinner,
  setTranslateSpinner,
  setXtmActiveProjectsSpinner,
  setXtmCustomerSpinner,
  setXtmLanguagesSpinner,
  setXtmTemplateSpinner,
  toggleJobActionSpinner,
} from './spinner.actions';
import { ISpinnerState } from './spinner.interface';

const initState: ISpinnerState = {
  authSpinner: false,
  xtmCustomerSpinner: false,
  xtmTemplateSpinner: false,
  xtmActiveProjectsSpinner: false,
  xtmLanguagesSpinner: false,
  jobsSpinner: false,
  jobActionSpinner: false,
  contentSpinner: {
    blogPosts: false,
    mails: false,
    pages: false,
    forms: false,
  },
  translateSpinner: false,
  previewSpinner: false,
};

export const spinnerReducer = createReducer(initState, (builder) =>
  builder
    .addCase(setAuthSpinner, (state, { payload }) => {
      state.authSpinner = payload;
    })
    .addCase(setXtmCustomerSpinner, (state, { payload }) => {
      state.xtmCustomerSpinner = payload;
    })
    .addCase(setXtmTemplateSpinner, (state, { payload }) => {
      state.xtmTemplateSpinner = payload;
    })
    .addCase(setXtmLanguagesSpinner, (state, { payload }) => {
      state.xtmLanguagesSpinner = payload;
    })
    .addCase(setXtmActiveProjectsSpinner, (state, { payload }) => {
      state.xtmActiveProjectsSpinner = payload;
    })
    .addCase(setJobsSpinner, (state, { payload }) => {
      state.jobsSpinner = payload;
    })
    .addCase(toggleJobActionSpinner, (state, { payload }) => {
      state.jobActionSpinner = payload;
    })
    .addCase(setTranslateSpinner, (state, { payload }) => {
      state.translateSpinner = payload;
    })
    .addCase(setPreviewSpinner, (state, { payload }) => {
      state.previewSpinner = payload;
    })
    .addCase(setContentSpinner, (state, { payload: { contentType, isLoading } }) => {
      switch (contentType) {
        case ContentTypeEnum.BLOG:
          state.contentSpinner.blogPosts = isLoading;
        case ContentTypeEnum.MAIL:
          state.contentSpinner.mails = isLoading;
        case ContentTypeEnum.PAGE:
          state.contentSpinner.pages = isLoading;
        case ContentTypeEnum.FORM:
          state.contentSpinner.forms = isLoading;
      }
    }),
);
