import { createAction } from '@reduxjs/toolkit';
import { StoreErrorPayload } from 'types/StoreErrorPayload';
import { AuthenticationResult } from 'utils/restApplicationClient';
import { AuthenticationRequest } from 'utils/restApplicationClientTypeOverrides';
import { AuthActionTypes } from './auth.actions.types';

export const login = createAction<AuthenticationRequest>(AuthActionTypes.Login);

export const loginError = createAction<StoreErrorPayload>(AuthActionTypes.LoginError);

export const loginSuccess = createAction<AuthenticationResult>(AuthActionTypes.LoginSuccess);

export const refreshToken = createAction(AuthActionTypes.RefreshToken);

export const refreshTokenSuccess = createAction<AuthenticationResult>(AuthActionTypes.RefreshTokenSuccess);

export const verifyTokenSuccess = createAction<AuthenticationResult>(AuthActionTypes.VerifyTokenSuccess);

export const logoutUser = createAction(AuthActionTypes.LogoutUser);

export const logoutUserSuccess = createAction(AuthActionTypes.LogoutUserSuccess);

export const oAuthRefreshSuccess = createAction<AuthenticationResult>(AuthActionTypes.OAuthRefreshSuccess);

export const oAuthRefreshError = createAction(AuthActionTypes.OAuthRefreshError);

export const oAuthConnect = createAction<string>(AuthActionTypes.OAuthConnect);

export const oAuthConnectSuccess = createAction(AuthActionTypes.OAuthConnectSuccess);
export const oAuthConnectError = createAction(AuthActionTypes.OAuthConnectError);
