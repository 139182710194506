import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { AppBar, Button } from '@material-ui/core';

const headerStyle = {
  title: css`
    font-size: 17px;
    padding-top: 15px;
  `,
};

export const HeaderLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderContainer = styled(AppBar)`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 16px 16px 18px 16px !important;
`;

export const HeaderButton = styled(Button)`
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #fff !important;
  transition: opacity 0.2s !important;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 4px;
  }
`;

export const HeaderLogoutButton = styled(HeaderButton)`
  color: #00a4e4 !important;
  font-weight: 500 !important;
`;

export default headerStyle;
