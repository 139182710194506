export const LanguagesActionTypes = {
  GetAvailableLanguageList: 'LANGUAGES_GET_AVAILABLE_LANGUAGE_LIST',
  GetAvailableLanguageListSuccess: 'LANGUAGES_GET_AVAILABLE_LANGUAGE_LIST_SUCCESS',
  GetAvailableLanguageListError: 'LANGUAGES_GET_AVAILABLE_LANGUAGE_LIST_ERROR',
  AddSourceLanguage: 'LANGUAGES_ADD_SOURCE_LANGUAGE',
  RemoveSourceLanguage: 'LANGUAGES_REMOVE_SOURCE_LANGUAGE',
  AddTargetLanguage: 'LANGUAGES_ADD_TARGET_LANGUAGE',
  RemoveTargetLanguage: 'LANGUAGES_REMOVE_TARGET_LANGUAGE',
  GetPageLanguages: 'LANGUAGES_GET_PAGE_LANGUAGES',
  GetPageLanguagesSuccess: 'LANGUAGES_GET_PAGE_LANGUAGES_SUCCESS',
  GetPageLanguagesError: 'LANGUAGES_GET_PAGE_LANGUAGES_ERROR',
  ClearSourceLanguages: 'LANGUAGES_CLEAR_SOURCE_LANGUAGES',
  ClearTargetLanguages: 'LANGUAGES_CLEAR_TARGET_LANGUAGES',
};
