import { createSelector } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import { AppState } from 'store';
import { ILanguagesState } from './languages.interface';

function getLanguages(
  languages: {
    [key: string]: {
      [key: string]: number;
    };
  },
  languagesCount: {
    [key: string]: number;
  },
): string[] {
  const allLanguages: { [key: string]: number } = {};
  const enumValues = Object.values(ContentTypeEnum);
  let count = 0;

  enumValues.forEach((key) => {
    const languageKeys = Object.keys(languages[key] || {});

    languageKeys.forEach((languageKey) => {
      if (allLanguages[languageKey]) {
        allLanguages[languageKey] += languages[key][languageKey];
      } else {
        allLanguages[languageKey] = languages[key][languageKey];
      }
    });
  });
  enumValues.forEach((key) => {
    count += languagesCount[key] || 0;
  });
  const keys = Object.keys(allLanguages);

  return keys.filter((key) => allLanguages[key] === count && count > 0);
}

function getLanguageCount(languageCount: { [key: string]: number }): number {
  let count = 0;
  const enumValues = Object.values(ContentTypeEnum);

  enumValues.forEach((key) => {
    count += languageCount[key] || 0;
  });
  return count;
}

const languagesStateSelector = ({ languages }: AppState): ILanguagesState => languages;

export const getLanguagesSelector = createSelector(
  languagesStateSelector,
  ({ availableLanguageList }: ILanguagesState) => availableLanguageList,
);

export const getSourceLanguageCountSelector = createSelector(
  languagesStateSelector,
  ({ sourceLanguageCount }: ILanguagesState) => getLanguageCount(sourceLanguageCount),
);

export const getTargetLanguageCountSelector = createSelector(
  languagesStateSelector,
  ({ targetLanguageCount }: ILanguagesState) => getLanguageCount(targetLanguageCount),
);

export const getPageLanguagesSelector = createSelector(
  languagesStateSelector,
  ({ pageLanguages }: ILanguagesState) => pageLanguages,
);

export const getSourceLanguagesSelector = createSelector(
  languagesStateSelector,
  ({ sourceLanguages, sourceLanguageCount }: ILanguagesState) => getLanguages(sourceLanguages, sourceLanguageCount),
);

export const getTargetLanguagesSelector = createSelector(
  languagesStateSelector,
  ({ targetLanguages, targetLanguageCount }: ILanguagesState) => getLanguages(targetLanguages, targetLanguageCount),
);
