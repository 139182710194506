import { createReducer } from '@reduxjs/toolkit';
import {
  clearActiveProjects,
  clearLanguagesAndActiveProjects,
  clearSettings,
  getActiveProjectsSuccess,
  getCustomersSuccess,
  getTemplatesByCustomerIdSuccess,
  importUserSettingsSuccess,
} from './settings.actions';
import { ISettingsState } from './settings.interface';

const initState: ISettingsState = {
  xtmCustomer: undefined,
  xtmTemplate: undefined,
  sourceLanguageData: undefined,
  targetLanguageData: undefined,
  defaultSourceLanguage: '',
  activeProjects: undefined,
  forceDefaultCustomerSettings: false,
};

export const settingsReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getCustomersSuccess, (state, action) => {
      state.xtmCustomer = action.payload;
    })
    .addCase(getTemplatesByCustomerIdSuccess, (state, action) => {
      state.xtmTemplate = action.payload;
    })
    .addCase(getActiveProjectsSuccess, (state, action) => {
      state.activeProjects = action.payload;
    })
    .addCase(importUserSettingsSuccess, (state, { payload: { defaultLanguage, forceDefaultCustomerSettings } }) => {
      state.defaultSourceLanguage = defaultLanguage;
      state.forceDefaultCustomerSettings = forceDefaultCustomerSettings;
    })
    .addCase(clearSettings, (state) => {
      state.xtmTemplate = undefined;
      state.sourceLanguageData = undefined;
      state.targetLanguageData = undefined;
      state.defaultSourceLanguage = '';
      state.activeProjects = undefined;
      state.forceDefaultCustomerSettings = false;
    })
    .addCase(clearLanguagesAndActiveProjects, (state) => {
      state.sourceLanguageData = undefined;
      state.targetLanguageData = undefined;
      state.defaultSourceLanguage = '';
      state.activeProjects = undefined;
    })
    .addCase(clearActiveProjects, (state) => {
      state.activeProjects = undefined;
    }),
);
