import { EntryStatusColor } from 'enums/entryStatusColor';
import { StatusEnum } from 'enums/status';

export const statusColorMap = {
  [StatusEnum.NEW]: EntryStatusColor.LIGHT_BLACK,
  [StatusEnum.IN_PROGRESS]: EntryStatusColor.BLUE,
  [StatusEnum.CANCELLED]: EntryStatusColor.RED,
  [StatusEnum.FINISHED]: EntryStatusColor.GREEN,
  [StatusEnum.SENT]: EntryStatusColor.GREY,
};
