import { EMAIL_REGEX, PASSWORD_REGEX } from './constants/customRegexp';
import { isObjectEqual } from './objectEquality';

/**
 * Returns whether given field has a value
 *
 * @param value - Form string value to test against
 *
 * @returns {string | null} Either null when validation is successful or translated error message
 */
export const required = (value: string): string | null => {
  if (value && typeof value === 'string') {
    return null;
  }
  return 'common.required';
};

/**
 * Returns whether given field has a value
 *
 * @param value - Form string value to test against
 *
 * @returns {string | null} Either null when validation is successful or translated error message
 */
export const requiredAutocomplete = (
  value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
): string | null => {
  if (Array.isArray(value) && value.length > 0) {
    return null;
  } else if (!Array.isArray(value) && value) {
    return null;
  }
  return 'common.required';
};

/**
 * Returns whether given field is a valid password
 *
 * @param value - Form string value to test against
 *
 * @returns {string | null} Either null when validation is successful or translated error message
 */
export const password = (value: string): string | null => {
  if (PASSWORD_REGEX.test(value)) {
    return null;
  }
  return 'Pasword is invalid. It should contain one or more of capital letter and number';
};

/**
 * Returns whether given field is a valid email
 *
 * @param value - Form string value to test against
 *
 * @returns {string | null} Either null when validation is successful or translated error message
 */
export const email = (value: string): string | null => {
  if (EMAIL_REGEX.test(value.trim())) {
    return null;
  }
  return 'Email is invalid';
};

/**
 * Returns reduced validator from given validator array
 *
 * @param validators - An array of validators
 *
 * @returns {(value: string) => string | null} A reduced validator function
 */
export const composeValidators =
  (validators: ((value: string) => string | null)[]): ((value: string) => string | null) =>
  (value: string) => {
    return validators.reduce((error: string | null, validator) => error || validator(value), null);
  };

/**
 * Returns whether a submit object has an error assigned to it in store and has different value than the one that threw an error
 *
 * @param error - An error returned from api
 *
 * @param previousValue - Values which caused error
 *
 * @returns {string | null} Wheter given value is the same as the one that threw an error
 */
export const fetchValidatorObject =
  (error: string | undefined, previousValue: Record<string, unknown>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (values: Record<string, any>): {} => {
    const errors: Record<string, unknown> = {};
    if (error && previousValue && isObjectEqual(values, previousValue)) {
      const valuesParameters = Object.keys(values);
      // eslint-disable-next-line array-callback-return
      valuesParameters.map((key) => {
        errors[key] = true;
      });
    }
    return errors;
  };
