import {
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CustomPagination from 'components/CustomPagination/CustomPagination';
import { ContentTypeEnum } from 'enums/contentType';
import { OrderEnum } from 'enums/orderEnum';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IContent, IHubspotSearchContent } from 'store/content/content.interface';
import {
  EntriesTableCell,
  EntriesTableCellName,
  EntriesTableContainer,
  EntriesTableHeaderCell,
  EntriesTableRow,
  EntriesTableSpinnerDiv,
} from './EntriesTable.styled';
import { withEntriesTable, WithEntriesTable } from './HOC/WithEntriesTable';

interface IProps {
  contentData: IContent;
  contentType: ContentTypeEnum;
  isLoading: boolean;
  disabledCheckboxes?: Array<number>;
}

type PropType = IProps & WithTranslation & WithEntriesTable;

export class EntriesTable extends Component<PropType> {
  renderRow = (entry: IHubspotSearchContent, index: number): JSX.Element | null => {
    const { t, disabledCheckboxes, handleCheckEntry, handlePreviewEntry } = this.props;
    const { showPreview, previewUrl } = this.props.preview;
    return (
      <EntriesTableRow key={entry.id} active={+(showPreview && previewUrl === entry?.screenshotPreviewUrl)}>
        <EntriesTableCell padding="checkbox">
          <Checkbox
            checked={entry.checked}
            onChange={handleCheckEntry(index, entry, entry.id)}
            disabled={disabledCheckboxes?.includes(index) || disabledCheckboxes?.includes(-1)}
          />
        </EntriesTableCell>
        <Tooltip title={entry.name}>
          <EntriesTableCellName>{entry.name}</EntriesTableCellName>
        </Tooltip>
        <EntriesTableCell>{moment(entry.updated).format('DD-MM-YYYY')}</EntriesTableCell>
        <Tooltip title={entry.authorName}>
          <EntriesTableCellName>{entry.authorName}</EntriesTableCellName>
        </Tooltip>
        <EntriesTableCellName>
          {`${entry.currentState.charAt(0)}${entry.currentState.slice(1).toLowerCase()}`}
        </EntriesTableCellName>
        <EntriesTableCell>
          <Tooltip
            title={
              <Fragment>
                {t(
                  `addcontent.${
                    !entry?.screenshotPreviewUrl
                      ? 'previewUnavailable'
                      : showPreview && previewUrl === entry?.screenshotPreviewUrl
                      ? 'hidePreview'
                      : 'showPreview'
                  }`,
                )}
              </Fragment>
            }
          >
            <span>
              <IconButton
                size="small"
                onClick={handlePreviewEntry(entry)}
                data-testid={'previewEntry'}
                disabled={!entry?.screenshotPreviewUrl}
              >
                {!entry?.screenshotPreviewUrl || (showPreview && previewUrl === entry?.screenshotPreviewUrl) ? (
                  <VisibilityOffIcon fontSize="default" />
                ) : (
                  <VisibilityIcon fontSize="default" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </EntriesTableCell>
      </EntriesTableRow>
    );
  };

  renderHeaders(): JSX.Element {
    const { t, checkForCheckedEntries, checkAllEntries, handleChangeOrder } = this.props;
    const {
      contentType,
      contentData,
      contentData: {
        pagination: { orderBy, order },
      },
      disabledCheckboxes,
    } = this.props;
    const headers = [
      { id: 'name', sortable: true },
      {
        id: contentType === ContentTypeEnum.FORM ? 'updatedAt' : 'updated',
        sortable: true,
      },
      { id: 'authorName', sortable: false },
      { id: 'currentState', sortable: false },
    ];
    const { checked, indeterminate } = checkForCheckedEntries(contentData?.data);

    return (
      <Fragment>
        <EntriesTableHeaderCell padding="checkbox">
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={checkAllEntries(indeterminate || !checked)}
            disabled={disabledCheckboxes ? disabledCheckboxes.length > 0 : false}
          />
        </EntriesTableHeaderCell>
        {headers.map((header, index) => {
          return header.sortable ? (
            <EntriesTableHeaderCell key={index} align="left" sortDirection={orderBy === header.id ? order : false}>
              <TableSortLabel
                active={orderBy === header.id}
                onClick={handleChangeOrder(header.id)}
                direction={orderBy === header.id ? order : OrderEnum.ASC}
                IconComponent={ArrowDropUp}
              >
                {t(`addcontent.headers.${header.id}`)}
              </TableSortLabel>
            </EntriesTableHeaderCell>
          ) : (
            <EntriesTableHeaderCell key={index} align="left">
              <TableHead component="div">{t(`addcontent.headers.${header.id}`)}</TableHead>
            </EntriesTableHeaderCell>
          );
        })}
        <EntriesTableHeaderCell></EntriesTableHeaderCell>
      </Fragment>
    );
  }

  render(): JSX.Element {
    const { t, contentData, isLoading, handleChangePage, handleChangeRowsPerPage } = this.props;
    return (
      <Fragment>
        <EntriesTableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>{this.renderHeaders()}</TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <EntriesTableSpinnerDiv>
                      <CircularProgress size={80} color="secondary" />
                    </EntriesTableSpinnerDiv>
                  </TableCell>
                </TableRow>
              ) : contentData?.data?.content && contentData?.data?.content?.length ? (
                contentData?.data?.content?.map(this.renderRow)
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography align="center" variant="body1" color="textSecondary">
                      {t('addcontent.emptyEntriesTable')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </EntriesTableContainer>

        <CustomPagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          defaultSize={contentData?.pagination?.size || 10}
          page={contentData?.pagination?.page || 0}
          totalPages={contentData?.data?.totalPages || 0}
          onPageChange={handleChangePage}
          onRowChange={handleChangeRowsPerPage}
        ></CustomPagination>
      </Fragment>
    );
  }
}

export default withEntriesTable(withTranslation()(EntriesTable));
