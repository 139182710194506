import styled from '@emotion/styled';
import { Container, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { EntriesTableHeaderCell } from 'components/Form/AddContent/EntriesTable/EntriesTable.styled';

export const TranslationQueueContentContainer = styled(Container)`
  padding-top: 12px;
  height: calc(100% - 158px);
`;

export const TranslationQueueTableHead = styled(TableHead)`
  background-color: #fafafa;
`;

export const TranslationQueueTableBody = styled(TableBody)`
  background-color: #fff;
`;

export const TranslationQueueSelectRowsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px;
  > p {
    margin-right: 8px;
  }
  cursor: pointer;
`;

export const TranslationQueueSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  flex-direction: column;
`;

export const TranslationQueueTableHeaderCell = styled(EntriesTableHeaderCell)`
  font-size: 13px !important;
  line-height: 15px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
`;

export const TranslationQueueTableContainer = styled(TableContainer)`
  max-height: calc(100% - 136px);
  overflow: auto;
  padding-right: 6px;
`;

export const TranslationQueueModalContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const TranslationQueuePaginationDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const TranslationQueueHeaderMenuContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;
