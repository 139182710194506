export enum EntryStatusColor {
  YELLOW = '#f79b0c',
  BLACK = '#000000',
  RED = '#e53935',
  GREEN = '#38AF49',
  BLUE = '#06A6E5',
  LIGHT_BLACK = '#2F3945',
  ORANGE = '#FF7057',
  GREY = '#78899B',
}
