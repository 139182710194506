/** @jsx jsx */
import { jsx } from '@emotion/core';
import LogoStyle from './Logo.style';
import logoWithTitle from './with-title.png';
import logoWithoutTitle from './without-title.svg';

interface IProps {
  withTitle?: boolean;
}
export const Logo = ({ withTitle }: IProps): JSX.Element => (
  <img
    src={!withTitle ? logoWithoutTitle : logoWithTitle}
    alt="XTM International"
    css={!withTitle ? LogoStyle.img : ''}
  />
);
