import { createReducer } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import { OrderEnum } from 'enums/orderEnum';
import { getPageLanguages } from 'store/languages/languages.actions';
import {
  checkAllEntries,
  checkEntry,
  clearCheckEntries,
  getContentSuccess,
  removeDisabledCheckbox,
  setPagination,
} from './content.actions';
import { IContent, IContentState } from './content.interface';
import { ContentService } from './content.service';

const initState: IContentState = {
  blogPostsState: {
    pagination: {
      page: 0,
      size: 10,
      order: OrderEnum.ASC,
      orderBy: 'name',
      search: '',
    },
  },
  mailsState: {
    pagination: {
      page: 0,
      size: 10,
      order: OrderEnum.ASC,
      orderBy: 'name',
      search: '',
    },
  },
  pagesState: {
    pagination: {
      page: 0,
      size: 10,
      order: OrderEnum.ASC,
      orderBy: 'name',
      search: '',
    },
  },
  formsState: {
    pagination: {
      page: 0,
      size: 10,
      order: OrderEnum.ASC,
      orderBy: 'name',
      search: '',
    },
  },
  disabledCheckboxes: [],
};

export const contentReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getContentSuccess, (state, { payload: { response, contentType } }) => {
      switch (contentType) {
        case ContentTypeEnum.BLOG:
          state.blogPostsState.data = ContentService.setContent(response);
          break;
        case ContentTypeEnum.MAIL:
          state.mailsState.data = ContentService.setContent(response);
          break;
        case ContentTypeEnum.PAGE:
          state.pagesState.data = ContentService.setContent(response);
          break;
        case ContentTypeEnum.FORM:
          state.formsState.data = ContentService.setContent(response);
          break;
      }
    })
    .addCase(setPagination, (state, { payload: { contentType, page, size, order, orderBy, search } }) => {
      const pagination = {
        page,
        size,
        order,
        orderBy,
        search,
      };

      switch (contentType) {
        case ContentTypeEnum.BLOG:
          state.blogPostsState.pagination = pagination;
          break;
        case ContentTypeEnum.MAIL:
          state.mailsState.pagination = pagination;
          break;
        case ContentTypeEnum.PAGE:
          state.pagesState.pagination = pagination;
          break;
        case ContentTypeEnum.FORM:
          state.formsState.pagination = pagination;
          break;
      }
    })
    .addCase(checkAllEntries, (state, { payload: { contentType, value } }) => {
      switch (contentType) {
        case ContentTypeEnum.BLOG:
          state.blogPostsState.data = ContentService.checkAllEntries(state.blogPostsState.data, value);
          break;
        case ContentTypeEnum.MAIL:
          state.mailsState.data = ContentService.checkAllEntries(state.mailsState.data, value);
          break;
        case ContentTypeEnum.PAGE:
          state.pagesState.data = ContentService.checkAllEntries(state.pagesState.data, value);
          break;
        case ContentTypeEnum.FORM:
          state.formsState.data = ContentService.checkAllEntries(state.formsState.data, value);
          break;
      }
    })
    .addCase(checkEntry, (state, { payload: { contentType, index, value } }) => {
      switch (contentType) {
        case ContentTypeEnum.BLOG:
          if (state.blogPostsState.data) {
            state.blogPostsState.data.content[index].checked = value;
          }
          break;
        case ContentTypeEnum.MAIL:
          if (state.mailsState.data) {
            state.mailsState.data.content[index].checked = value;
          }
          break;
        case ContentTypeEnum.PAGE:
          if (state.pagesState.data) {
            state.pagesState.data.content[index].checked = value;
          }
          break;
        case ContentTypeEnum.FORM:
          if (state.formsState.data) {
            state.formsState.data.content[index].checked = value;
          }
          break;
      }
    })
    .addCase(clearCheckEntries, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { disabledCheckboxes, ...contents } = state;
      const contentKeys = Object.keys(contents) as Array<keyof IContentState>;

      contentKeys.forEach((key) => {
        const { data } = state[key] as IContent;

        if (data) {
          // eslint-disable-next-line max-nested-callbacks
          data.content = data.content.map((content) => ({
            ...content,
            checked: false,
          }));
        }
      });
    })
    .addCase(getPageLanguages, (state, { payload: { index } }) => {
      state.disabledCheckboxes.push(index);
    })
    .addCase(removeDisabledCheckbox, ({ disabledCheckboxes }, { payload }) => {
      disabledCheckboxes.splice(disabledCheckboxes.indexOf(payload), 1);
    }),
);
