import { createReducer } from '@reduxjs/toolkit';
import { ContentTypeEnum } from 'enums/contentType';
import {
  addSourceLanguage,
  clearSourceLanguages,
  getAvailableLanguageListSuccess,
  getPageLanguagesSuccess,
  removeSourceLanguage,
  clearTargetLanguages,
  addTargetLanguage,
  removeTargetLanguage,
} from './languages.actions';
import { ILanguagesState, LanguageTypeEnum, IUpdateLanguages, LanguageFunctionTypeEnum } from './languages.interface';

const initState: ILanguagesState = {
  availableLanguageList: [],
  sourceLanguages: {},
  targetLanguages: {},
  pageLanguages: {},
  sourceLanguageCount: {},
  targetLanguageCount: {},
};

function modifyLanguageState(
  type: LanguageTypeEnum,
  functionType: LanguageFunctionTypeEnum,
  { languages, count, contentType }: IUpdateLanguages,
  state: ILanguagesState,
): void {
  const isSourceLanguage = type === LanguageTypeEnum.SOURCE;
  const isAddFunction = functionType === LanguageFunctionTypeEnum.ADD;
  const allLanguages = {
    ...(isSourceLanguage ? state.sourceLanguages[contentType] : state.targetLanguages[contentType] || {}),
  };
  const countStatement = count !== undefined ? count : 1;
  let languageCount = isSourceLanguage
    ? state.sourceLanguageCount[contentType]
    : state.targetLanguageCount[contentType] || 0;

  languages.forEach((language) => {
    const languageCount = allLanguages[language];

    allLanguages[language] = isAddFunction
      ? languageCount
        ? languageCount + 1
        : 1
      : languageCount
      ? languageCount - 1
      : 0;
  });

  if (isSourceLanguage ? state.sourceLanguageCount[contentType] : state.targetLanguageCount[contentType]) {
    languageCount = isAddFunction ? languageCount + countStatement : languageCount - countStatement;
  } else {
    languageCount = isAddFunction ? countStatement : 0;
  }

  if (isSourceLanguage) {
    state.sourceLanguages[contentType] = allLanguages;
    state.sourceLanguageCount[contentType] = languageCount;
  } else {
    state.targetLanguages[contentType] = allLanguages;
    state.targetLanguageCount[contentType] = languageCount;
  }
}

export const languagesReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getAvailableLanguageListSuccess, (state, action) => {
      state.availableLanguageList = action.payload.data;
    })
    .addCase(addSourceLanguage, (state, { payload }) => {
      modifyLanguageState(LanguageTypeEnum.SOURCE, LanguageFunctionTypeEnum.ADD, payload, state);
    })
    .addCase(addTargetLanguage, (state, { payload }) => {
      modifyLanguageState(LanguageTypeEnum.TARGET, LanguageFunctionTypeEnum.ADD, payload, state);
    })
    .addCase(removeSourceLanguage, (state, { payload }) => {
      modifyLanguageState(LanguageTypeEnum.SOURCE, LanguageFunctionTypeEnum.REMOVE, payload, state);
    })
    .addCase(removeTargetLanguage, (state, { payload }) => {
      modifyLanguageState(LanguageTypeEnum.TARGET, LanguageFunctionTypeEnum.REMOVE, payload, state);
    })
    .addCase(getPageLanguagesSuccess, (state, { payload }) => {
      const pageLanguages = { ...state.pageLanguages };
      payload.forEach(({ pageId, sourceLanguages }) => {
        pageLanguages[pageId] = sourceLanguages;
      });
      state.pageLanguages = pageLanguages;
    })
    .addCase(clearSourceLanguages, (state, { payload }) => {
      switch (payload) {
        case ContentTypeEnum.PAGE: {
          state.pageLanguages = {};
        }
        default: {
          state.sourceLanguages[payload] = {};
          state.sourceLanguageCount[payload] = 0;
        }
      }
    })
    .addCase(clearTargetLanguages, (state, { payload }) => {
      state.targetLanguages[payload] = {};
      state.targetLanguageCount[payload] = 0;
    }),
);
