export const JobsActionTypes = {
  QueryJobs: 'JOBS_QUERY',
  QueryJobsSuccess: 'JOBS_QUERY_SUCCESS',
  QueryJobsError: 'JOBS_QUERY_ERROR',
  PerformOperation: 'PERFORM_OPERTAION',
  PerformOperationSuccess: 'PERFORM_OPERTAION_SUCCESS',
  PerformOperationError: 'PERFORM_OPERTAION_ERROR',
  AddToSelectedJobs: 'ADD_TO_SELECTED_JOBS',
  AddAllToSelectedJobs: 'ADD_ALL_TO_SELECTED_JOBS',
  RemoveFromSelectedJobs: 'REMOVE_FROM_SELECTED_JOBS',
  ClearSelectedJobs: 'CLEAR_SELECTED_JOBS',
  GetSimilarOperationIds: 'GET_SIMILAR_OPERATION_IDS',
  GetSimilarOperationIdsSuccess: 'GET_SIMILAR_OPERATION_IDS_SUCCESS',
  GetSimilarOperationIdsError: 'GET_SIMILAR_OPERATION_IDS_ERROR',
  SetJobsPagination: 'SET_JOBS_PAGINATION',
  SetOpenedDialogs: 'SET_OPENED_DIALOGS',
};
