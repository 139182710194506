import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { api } from 'utils/axiosInstance';
import historyConst from 'utils/constants/history.const';
import { authEpics } from './auth/auth.epics';
import { IAuthState } from './auth/auth.interface';
import { authReducer } from './auth/auth.reducer';
import { contentEpics } from './content/content.epics';
import { IContentState } from './content/content.interface';
import { contentReducer } from './content/content.reducer';
import { errorEpics } from './error/error.epics';
import { IErrorState } from './error/error.interface';
import { errorReducer } from './error/error.reducer';
import { jobsEpics } from './jobs/jobs.epics';
import { IJobsState } from './jobs/jobs.interface';
import { jobsReducer } from './jobs/jobs.reducer';
import { languagesEpics } from './languages/languages.epics';
import { ILanguagesState } from './languages/languages.interface';
import { languagesReducer } from './languages/languages.reducer';
import { IPreviewState } from './preview/preview.interface';
import { previewReducer } from './preview/preview.reducer';
import { settingsEpics } from './settings/settings.epics';
import { ISettingsState } from './settings/settings.interface';
import { settingsReducer } from './settings/settings.reducer';
import { spinnerEpics } from './spinner/spinner.epics';
import { ISpinnerState } from './spinner/spinner.interface';
import { spinnerReducer } from './spinner/spinner.reducer';
import subscribeActionMiddleware from 'redux-subscribe-action';

export interface IStoreState {
  auth: IAuthState;
  jobs: IJobsState;
  spinner: ISpinnerState;
  error: IErrorState;
  settings: ISettingsState;
  preview: IPreviewState;
  content: IContentState;
  languages: ILanguagesState;
}

const appReducer = combineReducers({
  auth: authReducer,
  jobs: jobsReducer,
  spinner: spinnerReducer,
  error: errorReducer,
  settings: settingsReducer,
  preview: previewReducer,
  content: contentReducer,
  languages: languagesReducer,
});

const epicMiddleware = createEpicMiddleware({
  dependencies: { api, history: historyConst },
});

// TODO: modify appReducer to wrap clearing the store on logout
const store = configureStore({
  reducer: appReducer,
  middleware: [epicMiddleware, subscribeActionMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

epicMiddleware.run(
  combineEpics(settingsEpics, authEpics, errorEpics, spinnerEpics, jobsEpics, contentEpics, languagesEpics),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module.hot.accept('./', () => store.replaceReducer(appReducer));
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export default store;
