import { createAction } from '@reduxjs/toolkit';
import { ActiveProjectSearchDTO, UserConfigurationDTO, XTMProjectDTO } from 'utils/restApplicationClient';
import { GetCustomersDTO, GetTemplatesDTO } from 'utils/restApplicationClientTypeOverrides';
import { SettingsActionTypes } from './settings.actions.types';

export const getCustomers = createAction(SettingsActionTypes.GetCustomers);

export const getCustomersSuccess = createAction<GetCustomersDTO>(SettingsActionTypes.GetCustomersSuccess);

export const getCustomersError = createAction(SettingsActionTypes.GetCustomersError);

export const getTemplatesByCustomerId = createAction<number>(SettingsActionTypes.GetTemplatesByCustomerId);

export const getTemplatesByCustomerIdSuccess = createAction<GetTemplatesDTO>(
  SettingsActionTypes.GetTemplatesByCustomerIdSuccess,
);

export const getTemplatesByCustomerIdError = createAction(SettingsActionTypes.GetTemplatesByCustomerIdError);

export const getActiveProjects = createAction<ActiveProjectSearchDTO>(SettingsActionTypes.GetActiveProjects);

export const getActiveProjectsSuccess = createAction<XTMProjectDTO[]>(SettingsActionTypes.GetActiveProjectsSuccess);

export const getActiveProjectsError = createAction(SettingsActionTypes.GetActiveProjectsError);

export const importUserSettings = createAction(SettingsActionTypes.ImportUserSettings);

export const importUserSettingsSuccess = createAction<UserConfigurationDTO>(
  SettingsActionTypes.ImportUserSettingsSuccess,
);

export const importUserSettingsError = createAction(SettingsActionTypes.ImportUserSettingsError);

export const clearSettings = createAction(SettingsActionTypes.ClearSettings);

export const clearLanguagesAndActiveProjects = createAction(SettingsActionTypes.ClearLanguagesActiveProjects);

export const clearActiveProjects = createAction(SettingsActionTypes.ClearActiveProjects);
