import { css } from '@emotion/core';

const logoStyle = {
  img: css`
    width: 103px;
    height: auto;
  `,
};

export default logoStyle;
