import { Dispatch } from '@reduxjs/toolkit';
import { FormApi } from 'final-form';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { sendForTranslation } from 'store/content/content.actions';
import { IContentState } from 'store/content/content.interface';
import { getContentSelector } from 'store/content/content.selectors';
import {
  getLanguagesSelector,
  getSourceLanguageCountSelector,
  getTargetLanguageCountSelector,
} from 'store/languages/languages.selectors';
import {
  clearActiveProjects,
  clearLanguagesAndActiveProjects,
  clearSettings,
  getActiveProjects,
  getCustomers,
  getTemplatesByCustomerId,
  importUserSettings,
} from 'store/settings/settings.actions';
import { ActiveProjectSearchDTO, HubspotLanguageDTO, XTMProjectEntriesVerifyDTO } from 'utils/restApplicationClient';
import { GetCustomersDTO, GetTemplatesDTO, TranslateRequestDTO } from 'utils/restApplicationClientTypeOverrides';
import { IAddContentForm } from '../AddContentForm';
import addContentParser, { AddContentParser } from './WithAddContent.parser';

export interface WithAddContent {
  clearInputs: (form: FormApi<IAddContentForm>, keys: Array<keyof IAddContentForm>) => void;
  getDefaultCustomer: (
    customerDTO: GetCustomersDTO | undefined,
    parsedCustomers: Array<{ value: string; label: string }>,
  ) => { value: string; label: string } | undefined;
  getDefaultTemplate: (
    templateDTO: GetTemplatesDTO | undefined,
    parsedTemplates: Array<{ value: string; label: string }>,
  ) => { value: string; label: string } | undefined;
  getDefaultLanguage: (
    sourceLanguage: string,
    parsedSourceLanguages: Array<{ value: string; label: string }>,
  ) => { value: string; label: string } | undefined;
  handleCustomerChange: (
    form: FormApi<IAddContentForm>,
  ) => (
    _: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  handleTemplateChange: (
    form: FormApi<IAddContentForm>,
  ) => (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  handleSourceLanguageChange: (
    form: FormApi<IAddContentForm>,
  ) => (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  handleActiveProjectChange: (
    form: FormApi<IAddContentForm>,
  ) => (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  handleTargetLanguagesChange: (
    form: FormApi<IAddContentForm>,
  ) => (
    event: React.ChangeEvent<{}>,
    value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
  ) => void;
  getCustomers: () => AppDispatch;
  sendForTranslation: (
    values: IAddContentForm,
    verifyEntries?: XTMProjectEntriesVerifyDTO[],
    activeProjectId?: string,
  ) => void;
  contentState: IContentState;
  targetLanguages: Array<HubspotLanguageDTO>;
  sourceLanguages: Array<HubspotLanguageDTO>;
  parser: AddContentParser;
}

interface IDispatchProps {
  getCustomers: () => AppDispatch;
  getTemplatesByCustomerId: (payload: number) => AppDispatch;
  getActiveProjects: (payload: ActiveProjectSearchDTO) => AppDispatch;
  clearSettings: () => AppDispatch;
  importUserSettings: () => AppDispatch;
  clearActiveProject: () => AppDispatch;
  clearLanguagesAndActiveProject: () => AppDispatch;
  sendForTranslation: (payload: TranslateRequestDTO) => AppDispatch;
}

interface IStateProps {
  contentState: IContentState;
  languages: Array<HubspotLanguageDTO>;
  sourceLanguagesCount: number;
  targetLanguagesCount: number;
}

interface IState {
  targetLanguages: Array<HubspotLanguageDTO>;
  sourceLanguages: Array<HubspotLanguageDTO>;
}

const mapStateToProps = (state: AppState): IStateProps => ({
  contentState: getContentSelector(state),
  languages: getLanguagesSelector(state),
  sourceLanguagesCount: getSourceLanguageCountSelector(state),
  targetLanguagesCount: getTargetLanguageCountSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getCustomers: (): AppDispatch => dispatch(getCustomers()),
  getTemplatesByCustomerId: (payload: number): AppDispatch => dispatch(getTemplatesByCustomerId(payload)),
  importUserSettings: (): AppDispatch => dispatch(importUserSettings()),
  getActiveProjects: (payload: ActiveProjectSearchDTO): AppDispatch => dispatch(getActiveProjects(payload)),
  clearSettings: (): AppDispatch => dispatch(clearSettings()),
  clearActiveProject: (): AppDispatch => dispatch(clearActiveProjects()),
  clearLanguagesAndActiveProject: (): AppDispatch => dispatch(clearLanguagesAndActiveProjects()),
  sendForTranslation: (payload: TranslateRequestDTO): AppDispatch => dispatch(sendForTranslation(payload)),
});

type PropType = IDispatchProps & IStateProps;

export const withAddContent = <P extends WithAddContent>(
  WrappedComponent: React.ComponentType<P>,
): ConnectedComponent<React.ComponentType<P>, Pick<unknown, never>> => {
  class WithAddContent extends React.Component<PropType & P, IState> {
    constructor(props: PropType & P) {
      super(props);
      const { languages } = this.props;
      this.state = {
        sourceLanguages: [...languages],
        targetLanguages: [...languages],
      };
    }

    componentDidUpdate(previousProps: PropType): void {
      const { languages, sourceLanguagesCount, targetLanguagesCount } = this.props;
      const { sourceLanguages, targetLanguages } = this.state;

      if (previousProps.languages.length !== languages.length) {
        this.setState({
          sourceLanguages: [...languages],
          targetLanguages: [...languages],
        });
      }

      if (!sourceLanguagesCount && previousProps.sourceLanguagesCount && sourceLanguages?.length !== languages.length) {
        this.setState({
          sourceLanguages: [...languages],
        });
      }

      if (!targetLanguagesCount && previousProps.targetLanguagesCount && targetLanguages?.length !== languages.length) {
        this.setState({
          targetLanguages: [...languages],
        });
      }
    }

    private parser = addContentParser;

    sendForTranslation = (
      values: IAddContentForm,
      verifyEntries?: XTMProjectEntriesVerifyDTO[],
      activeProjectId?: string,
    ): void => {
      const {
        contentState: { blogPostsState, formsState, mailsState, pagesState },
        sendForTranslation,
      } = this.props;
      const entries = this.parser.filterEntriesForTranslation(
        {
          blogPostsState,
          formsState,
          mailsState,
          pagesState,
        },
        verifyEntries,
        activeProjectId,
      );
      const {
        xtmCustomer: { value: xtmCustomerId },
        xtmTemplate: { value: xtmTemplateId },
        sourceLanguage: { value: sourceLanguage },
        targetLanguages,
        dueDate,
        projectName,
      } = values;

      sendForTranslation({
        customerId: Number(xtmCustomerId),
        templateId: Number(xtmTemplateId),
        products: entries,
        sourceLanguage,
        targetLanguages: targetLanguages.map(({ value }) => value),
        dueDate: this.calculateAndParseDate(dueDate),
        serverUrl: window.location.origin,
        projectName,
      });
    };

    fetchActiveProjects = (
      sourceLanguage: string,
      targetLanguages: Array<string>,
      xtmCustomer: IAddContentForm['xtmCustomer'],
    ): void => {
      this.props.getActiveProjects({
        sourceLanguage,
        targetLanguages,
        customerId: Number(xtmCustomer.value),
      });
    };

    handleCustomerChange =
      (form: FormApi<IAddContentForm>) =>
      (
        _: React.ChangeEvent<{}>,
        value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
      ): void => {
        // New customer was chosen
        const { clearSettings, getTemplatesByCustomerId, importUserSettings } = this.props;

        this.clearInputs(form, ['xtmTemplate', 'sourceLanguage', 'targetLanguages', 'activeProject']);
        clearSettings();

        if (value && !Array.isArray(value)) {
          getTemplatesByCustomerId(Number(value.value));
          importUserSettings();
        }
      };

    handleTemplateChange =
      (form: FormApi<IAddContentForm>) =>
      (
        event: React.ChangeEvent<{}>,
        value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
      ): void => {
        const { clearLanguagesAndActiveProject } = this.props;

        this.clearInputs(form, ['sourceLanguage', 'targetLanguages', 'activeProject']);
        // Template was cleared

        if (!value) {
          clearLanguagesAndActiveProject();
        }
      };

    handleSourceLanguageChange =
      (form: FormApi<IAddContentForm>) =>
      (
        event: React.ChangeEvent<{}>,
        value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
      ): void => {
        const { targetLanguages, xtmCustomer } = form.getState().values;

        this.clearInputs(form, ['activeProject']);
        if (value && !Array.isArray(value)) {
          this.setState({
            targetLanguages: this.props.languages.filter((language) => language.code != value.value),
          });

          if (targetLanguages?.length > 0) {
            this.fetchActiveProjects(
              value.value,
              targetLanguages.map((language) => language.value),
              xtmCustomer,
            );
            this.removeTargetLanguage(form, targetLanguages, value.value);
          }
        } else {
          this.props.clearActiveProject();
          this.setState({
            targetLanguages: this.props.languages,
          });
        }
      };

    handleTargetLanguagesChange =
      (form: FormApi<IAddContentForm>) =>
      (
        event: React.ChangeEvent<{}>,
        value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
      ): void => {
        const { values } = form.getState();
        const { sourceLanguage, xtmCustomer } = values;

        this.clearInputs(form, ['activeProject']);
        if (Array.isArray(value) && value.length > 0 && sourceLanguage && xtmCustomer) {
          this.fetchActiveProjects(
            sourceLanguage.value,
            value.map((language) => language.value),
            xtmCustomer,
          );
        } else {
          // Target languages were cleared
          this.props.clearActiveProject();
        }
      };

    handleActiveProjectChange =
      (form: FormApi<IAddContentForm>) =>
      (
        event: React.ChangeEvent<{}>,
        value: { label: string; value: string } | Array<{ label: string; value: string }> | null,
      ): void => {
        if (value && !Array.isArray(value)) {
          form.change('projectName', value.label);
        } else {
          form.change('projectName', undefined);
        }
      };

    clearInputs(form: FormApi<IAddContentForm>, keys: Array<keyof IAddContentForm>): void {
      keys.forEach((key) => {
        form.change(key, undefined);
      });
    }

    removeTargetLanguage(
      form: FormApi<IAddContentForm>,
      targetLanguages: IAddContentForm['targetLanguages'],
      sourceLanguage: string,
    ): void {
      form.change(
        'targetLanguages',
        targetLanguages.filter((language) => language.value !== sourceLanguage),
      );
    }

    getDefaultCustomer = (
      customerDTO: GetCustomersDTO | undefined,
      parsedCustomers: Array<{ value: string; label: string }>,
    ): { value: string; label: string } | undefined => {
      return customerDTO && this.getDefault(customerDTO, 'defaultCustomerId', parsedCustomers);
    };

    getDefaultTemplate = (
      templateDTO: GetTemplatesDTO | undefined,
      parsedTemplates: Array<{ value: string; label: string }>,
    ): { value: string; label: string } | undefined => {
      return templateDTO && this.getDefault(templateDTO, 'defaultTemplateId', parsedTemplates);
    };

    getDefaultLanguage = (
      sourceLanguage: string,
      parsedSourceLanguages: Array<{ value: string; label: string }>,
    ): { value: string; label: string } | undefined => {
      return parsedSourceLanguages.find(({ value }) => value === sourceLanguage);
    };

    calculateAndParseDate = (dateString: string): Date | undefined => {
      if (dateString) {
        const now = moment();
        const date = moment(dateString);

        if (date.isBefore(now)) {
          date.hour(now.hour() + 1).minute(0);
        }
        return date.toDate();
      }
      return undefined;
    };

    getDefault<T>(
      objectToParse: T,
      defaultKeyId: keyof T,
      parsedObjects: Array<{ value: string; label: string }>,
    ): { value: string; label: string } | undefined {
      const defaultId = objectToParse[defaultKeyId];

      if (defaultId) {
        return parsedObjects.find(
          (parsed) =>
            (typeof defaultId === 'number' && parsed.value === defaultId.toString()) ||
            (typeof defaultId === 'string' && parsed.value === defaultId),
        );
      }
      return undefined;
    }

    render(): JSX.Element {
      const { sourceLanguages, targetLanguages } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          clearInputs={this.clearInputs}
          getDefaultCustomer={this.getDefaultCustomer}
          getDefaultTemplate={this.getDefaultTemplate}
          getDefaultLanguage={this.getDefaultLanguage}
          handleCustomerChange={this.handleCustomerChange}
          handleTemplateChange={this.handleTemplateChange}
          handleSourceLanguageChange={this.handleSourceLanguageChange}
          handleTargetLanguagesChange={this.handleTargetLanguagesChange}
          handleActiveProjectChange={this.handleActiveProjectChange}
          sendForTranslation={this.sendForTranslation}
          targetLanguages={targetLanguages}
          sourceLanguages={sourceLanguages}
          parser={this.parser}
        />
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return connect(mapStateToProps, mapDispatchToProps)(WithAddContent as any);
};
