import { Button } from '@material-ui/core';
import styled from '@emotion/styled';

export const RefreshButton = styled(Button)`
  text-transform: none !important;
  :hover {
    background-color: transparent !important;
  }
`;

export const TableToolbarDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;

  > button:first-of-type {
    margin-right: auto;
  }
`;
