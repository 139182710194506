import styled from '@emotion/styled';
import { Select } from '@material-ui/core';

export const CustomPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;
`;

export const CustomPaginationActionsContainer = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;

  .MuiPaginationItem-textSecondary.Mui-selected {
    background-color: #eff3f6;
    color: #00a4e4;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const CustomPaginationSelect = styled(Select)`
  margin: 0 8px;
`;
