import { ContentTypeEnum } from 'enums/contentType';
import { HubspotLanguageDTO } from 'utils/restApplicationClient';

export interface ILanguagesState {
  availableLanguageList: HubspotLanguageDTO[];
  sourceLanguages: { [key: string]: { [key: string]: number } };
  targetLanguages: { [key: string]: { [key: string]: number } };
  pageLanguages: { [key: string]: Array<string> };
  sourceLanguageCount: { [key: string]: number };
  targetLanguageCount: { [key: string]: number };
}

export interface IUpdateLanguages {
  languages: Array<string>;
  contentType: ContentTypeEnum;
  count?: number;
}

export interface IGetPageLanguages {
  pageIds: Array<string>;
  index: number;
}

export enum LanguageTypeEnum {
  SOURCE = 'SOURCE',
  TARGET = 'TARGET',
}

export enum LanguageFunctionTypeEnum {
  REMOVE = 'REMOVE',
  ADD = 'ADD',
}
