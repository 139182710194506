import { Box, Dialog, DialogTitle } from '@material-ui/core';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { DialogVariantEnum } from 'enums/dialogVariant';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import InTranslation from './Content/InTranslation';
import JobAction, { IJobActionProps } from './Content/JobAction';

interface IProps {
  open: boolean;
  variant: DialogVariantEnum;
  onClose: (response: ButtonVariantEnum, variant?: DialogVariantEnum) => void;
  jobIds?: string[];
}

type PropType = IProps & WithTranslation;

class CustomDialog extends Component<PropType> {
  private getVariantText = (): string | undefined => {
    const { variant } = this.props;

    switch (variant) {
      case DialogVariantEnum.IN_TRANSLATION:
      case DialogVariantEnum.SIMILAR_OPERATION: {
        return 'modal.confirm';
      }
      case DialogVariantEnum.IMPORT: {
        return 'modal.import';
      }
      case DialogVariantEnum.DELETE: {
        return 'modal.delete';
      }
      case DialogVariantEnum.CANCEL: {
        return 'modal.cancel';
      }
      case DialogVariantEnum.SEND: {
        return 'modal.send';
      }
      default:
        return undefined;
    }
  };

  private getVariantComponent = (): JSX.Element | undefined => {
    const { variant, onClose, jobIds } = this.props;
    const config: IJobActionProps = { onClose, jobIds: jobIds || [''] };

    switch (variant) {
      case DialogVariantEnum.IN_TRANSLATION: {
        return <InTranslation onClose={onClose} />;
      }
      case DialogVariantEnum.IMPORT: {
        return <JobAction {...config} variant={DialogVariantEnum.IMPORT} />;
      }
      case DialogVariantEnum.DELETE: {
        return <JobAction {...config} variant={DialogVariantEnum.DELETE} />;
      }
      case DialogVariantEnum.CANCEL: {
        return <JobAction {...config} variant={DialogVariantEnum.CANCEL} />;
      }
      case DialogVariantEnum.SIMILAR_OPERATION: {
        return <JobAction {...config} variant={DialogVariantEnum.SIMILAR_OPERATION} />;
      }
      default:
        return undefined;
    }
  };

  render(): JSX.Element {
    const { open, t } = this.props;

    return (
      <Dialog open={open} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.38)' } }}>
        <DialogTitle>
          <Box fontWeight="500" color="#333" fontSize="15px">
            {t(this.getVariantText() || '')}
          </Box>
        </DialogTitle>
        {this.getVariantComponent()}
      </Dialog>
    );
  }
}

export default withTranslation()(CustomDialog);
