import { createReducer } from '@reduxjs/toolkit';
import { OrderEnum } from 'enums/orderEnum';
import {
  addToSelectedJobs,
  getSimilarOperationIdsSuccess,
  queryJobsSuccess,
  removeFromSelectedJobs,
  setJobsPagination,
  clearSelectedJobs,
  addAllToSelectedJobs,
  setOpenedDialogs,
} from './jobs.actions';
import { IJobsState } from './jobs.interface';

const initState: IJobsState = {
  jobs: [],
  pagination: {
    sort: '',
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 5,
    order: OrderEnum.ASC,
    orderBy: 'project.projectName',
    search: '',
  },
  openedDialogs: [],
  selectedJobs: [],
  similarIds: [],
};

export const jobsReducer = createReducer(initState, (builder) =>
  builder
    .addCase(queryJobsSuccess, (state, { payload: { content, totalPages, totalElements, size, number } }) => {
      state.jobs = content;
      state.pagination = {
        ...state.pagination,
        totalElements,
        totalPages,
        page: number,
        size,
      };
    })
    .addCase(getSimilarOperationIdsSuccess, (state, action) => {
      state.similarIds = action.payload.data;
    })
    .addCase(setJobsPagination, (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    })
    .addCase(addToSelectedJobs, (state, action) => {
      const isInArray = !!state.selectedJobs.find((job) => job.id === action.payload.id);

      if (!isInArray) {
        state.selectedJobs.push(action.payload);
      }
    })
    .addCase(addAllToSelectedJobs, (state) => {
      state.selectedJobs = state.jobs;
    })
    .addCase(setOpenedDialogs, (state, action) => {
      state.openedDialogs = action.payload;
    })
    .addCase(removeFromSelectedJobs, (state, action) => {
      state.selectedJobs = state.selectedJobs.filter((job) => job.id !== action.payload.id);
    })
    .addCase(clearSelectedJobs, (state) => {
      state.selectedJobs = [];
    }),
);
