import { IContent } from 'store/content/content.interface';
import {
  HubspotLanguageDTO,
  TranslationProduct,
  XTMProjectDTO,
  XTMProjectEntriesVerifyDTO,
} from 'utils/restApplicationClient';
import { GetCustomersDTO, GetTemplatesDTO } from 'utils/restApplicationClientTypeOverrides';
export class AddContentParser {
  parseLanguages = (
    languages: Array<HubspotLanguageDTO> | undefined,
    availableLanguages: Array<string> | undefined,
    count?: number,
  ): Array<{ value: string; label: string }> => {
    if (availableLanguages && count && count > 0 && languages) {
      const filteredAvailableLanguages = availableLanguages
        .map((language) => languages.find((value) => value.code === language))
        .filter((value) => value !== undefined);

      const parsedAvailableLanguages = this.parseAutocomplete(
        filteredAvailableLanguages.length > 0 ? (filteredAvailableLanguages as Array<HubspotLanguageDTO>) : undefined,
        'name',
        'code',
      );
      return Array.isArray(parsedAvailableLanguages) ? parsedAvailableLanguages : [];
    }
    const parsedLanguages = this.parseAutocomplete(languages, 'name', 'code');

    return Array.isArray(parsedLanguages) ? parsedLanguages : [];
  };

  parseCustomers = (customerDTO?: GetCustomersDTO): Array<{ value: string; label: string }> => {
    const parsedCustomers = this.parseAutocomplete(customerDTO?.customers, 'name', 'xtmCustomerId');
    return Array.isArray(parsedCustomers) ? parsedCustomers : [];
  };

  parseTemplates = (templateDTO?: GetTemplatesDTO): Array<{ value: string; label: string }> => {
    const parsedTemplates = this.parseAutocomplete(templateDTO?.templates, 'name', 'xtmTemplateId');
    return Array.isArray(parsedTemplates) ? parsedTemplates : [];
  };

  parseProjects = (projects?: Array<XTMProjectDTO>): Array<{ value: string; label: string }> => {
    if (projects) {
      const parsedProjects = this.parseAutocomplete(projects, 'name', 'id');
      return Array.isArray(parsedProjects) ? parsedProjects : [];
    }
    return [];
  };

  filterCheckedEntries = (data: { [key: string]: IContent }): Array<TranslationProduct> => {
    const entries: Array<TranslationProduct> = [];
    const keys = Object.keys(data);

    keys.forEach((key) => {
      const temporaryData = data[key].data;

      if (temporaryData) {
        entries.push(
          ...temporaryData.content
            .filter(({ checked }) => checked)
            .map(({ id, type }) => ({ productId: id, productType: type })),
        );
      }
    });

    return entries;
  };

  filterEntriesForTranslation = (
    data: {
      [key: string]: IContent;
    },
    verifyEntries?: XTMProjectEntriesVerifyDTO[],
    activeProjectId?: string,
  ): { [index: string]: TranslationProduct[] } => {
    //TODO TEST AFTER BE CHANGE VERIFY EP
    const entries: { [index: string]: TranslationProduct[] } = {};
    const allEntries = this.filterCheckedEntries(data);

    if (verifyEntries) {
      const foundProjects = verifyEntries.filter(({ projectId, entryId }) => {
        const foundEntry = allEntries.find(({ productId }) => productId === entryId);

        if (foundEntry) {
          entries[projectId] = entries[projectId] ? [...entries[projectId], foundEntry] : [foundEntry];
        }

        return foundEntry;
      });

      const newEntries = allEntries.filter(
        ({ productId }) => !foundProjects.find(({ entryId }) => productId === entryId),
      );

      if (!!foundProjects.length && !!newEntries.length) {
        entries[activeProjectId ? activeProjectId : 0] = newEntries;
      }

      if (!!!foundProjects.length) {
        entries[activeProjectId ? activeProjectId : 0] = allEntries;
      }
    } else {
      entries[activeProjectId ? activeProjectId : 0] = allEntries;
    }

    return entries;
  };

  private parseAutocomplete<T>(
    objectsToParse: Array<T> | undefined,
    labelKey: keyof T,
    valueKey: keyof T,
  ): Array<{ value: string; label: string }> | { value: string; label: string } {
    if (objectsToParse === undefined || objectsToParse === null) {
      return [];
    } else {
      return objectsToParse.map((object) => {
        if (typeof object === 'string') {
          return {
            label: object,
            value: object,
          };
        }
        return {
          label: (object[labelKey] as unknown as string).toString(),
          value: (object[valueKey] as unknown as string).toString(),
        };
      });
    }
  }
}

export default new AddContentParser();
