import styled from '@emotion/styled';

export const ContentTableTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentTableTab = styled.div<{ active?: boolean }>`
  padding: 8px 16px;
  margin-right: 4px;
  background-color: ${(props): string => (props?.active ? '#DCE5ED' : '#fff')};
  text-shadow: ${(props): string => (props?.active ? '-0.06ex 0 #6F8294, 0.06ex 0 #6F8294;' : '0')};
  font-size: 13px;
  color: ${(props): string => (props?.active ? '#6F8294' : '#555')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  border: 0.5px solid #dddddd;
  border-bottom: none;

  &:hover {
    cursor: pointer;
    background-color: #dce5ed;
  }
`;

export const ContentTableTableContainer = styled.div`
  height: calc(100% - 116px);
`;
