import { Box, MenuItem, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  CustomPaginationActionsContainer,
  CustomPaginationContainer,
  CustomPaginationSelect,
} from './CustomPagination.styled';

interface IProps {
  rowsPerPageOptions: number[];
  defaultSize: number;
  page: number;
  totalElements?: number;
  totalPages: number;
  onPageChange?: (page: number) => void;
  onRowChange?: (size: number) => void;
}

type PropType = IProps & WithTranslation;

class CustomPagination extends Component<PropType> {
  onRowChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    if (this.props.onRowChange) {
      this.props.onRowChange(Number(event.target.value));
    }
  };

  onPageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    if (this.props.onPageChange) {
      this.props.onPageChange(Number(page - 1));
    }
  };

  render(): JSX.Element {
    const { t, page, totalPages, rowsPerPageOptions, defaultSize } = this.props;

    return (
      <CustomPaginationContainer>
        <Typography component="span" variant="body2">
          <Box fontSize="12px" color="#333">
            {t('queue.rows')}
          </Box>
        </Typography>
        <CustomPaginationSelect value={defaultSize} onChange={this.onRowChange}>
          {rowsPerPageOptions.map((rowOption, index) => (
            <MenuItem key={index} value={rowOption}>
              {rowOption}
            </MenuItem>
          ))}
        </CustomPaginationSelect>
        <CustomPaginationActionsContainer>
          <Typography component="span" variant="body2">
            <Box fontSize="12px" color="#333">
              {t('queue.page')}
            </Box>
          </Typography>
          <Pagination size="small" count={totalPages} color="secondary" page={page + 1} onChange={this.onPageChange} />
        </CustomPaginationActionsContainer>
      </CustomPaginationContainer>
    );
  }
}

export default withTranslation()(CustomPagination);
