import { Tabs } from '@material-ui/core';
import { RouterUrlEnum } from 'enums/routerUrl';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TrustedRoutes } from 'utils/constants/router.const';
import { CustomNavbarContainer, CustomNavbarTab } from './CustomNavbar.styled';

interface IState {
  selectedTab: string;
}

type PropType = WithTranslation & RouteComponentProps;

class CustomNavbar extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = { selectedTab: RouterUrlEnum.CONTENT };
  }

  componentDidMount(): void {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const selectedRoute =
      !!TrustedRoutes.find((route) => route.pathname === pathname) && pathname !== RouterUrlEnum.HOME
        ? pathname
        : RouterUrlEnum.CONTENT;

    this.redirect(selectedRoute);
  }

  onTabChange = (_event: React.ChangeEvent<{}>, newValue: string): void => {
    this.redirect(newValue);
  };

  redirect = (newValue: string): void => {
    const { history } = this.props;

    this.setState({
      selectedTab: newValue,
    });
    history.push(newValue);
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { selectedTab } = this.state;

    return (
      <CustomNavbarContainer>
        <Tabs value={selectedTab} textColor="secondary" onChange={this.onTabChange} aria-label="tab">
          <CustomNavbarTab
            label={t('common.tab.addContentTab')}
            id="add-content"
            data-testid={'add-content'}
            value={RouterUrlEnum.CONTENT}
          />
          <CustomNavbarTab
            label={t('common.tab.translationQueue')}
            id="translation-queue"
            data-testid={'translation-queue'}
            value={RouterUrlEnum.TRANSLATION}
          />
        </Tabs>
      </CustomNavbarContainer>
    );
  }
}

export default withTranslation()(withRouter(CustomNavbar));
